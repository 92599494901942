export const formatDay = (value) => {
  if (!value || value === '') {
    return '';
  }

  let parts;
  if (value.includes('-')) {
    parts = value.split('-');
  } else {
    parts = value.split('/');
  }

  if (parts.length !== 2 && parts.length !== 3) {
    return '';
  }

  const isLeadingYear = parts[0].length === 4;
  if (isLeadingYear) {
    const [year, month, day] = parts;
    return `${day}-${month}-${year}`;
  }

  const noDay = parts.length === 2;
  if (noDay) {
    const [month, year] = parts;
    return `${month}-${year}`;
  }

  const [day, month, year] = parts;
  return `${day}-${month}-${year}`;
};

export const isValidExpiryDate = (expiryDate) => {
  const formattedExpiryDate = formatDay(expiryDate);
  const defaultYear = '1970';
  return formattedExpiryDate && !formattedExpiryDate.includes(defaultYear);
};
