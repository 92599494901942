const ANIMATION_FOLDER_BASE_URL = '/animations';

export const ANIMATIONS = {
  CARD: `${ANIMATION_FOLDER_BASE_URL}/anim_01_var_01.json`,
  PASSPORT: `${ANIMATION_FOLDER_BASE_URL}/anim_passport.json`,
  REVIEW: `${ANIMATION_FOLDER_BASE_URL}/anim_data.json`,
  FACE_ID: `${ANIMATION_FOLDER_BASE_URL}/anim_face_id.json`,
  BACK_CARD: `${ANIMATION_FOLDER_BASE_URL}/anim_back_card.json`,
  CLOSE_FAR: `${ANIMATION_FOLDER_BASE_URL}/anim_close_far.json`,
  LAPTOP: `${ANIMATION_FOLDER_BASE_URL}/anim_laptop.json`,
  LAMP: `${ANIMATION_FOLDER_BASE_URL}/anim_lamp.json`,
  PROOF_OF_ADDRESS: `${ANIMATION_FOLDER_BASE_URL}/anim_proof_of_address.json`,
  SIDE_OF_CARD: `${ANIMATION_FOLDER_BASE_URL}/anim_side_of_card.json`
};
