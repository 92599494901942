import Message from '@lib/components/v2/Message';
import React, { useEffect, useState } from 'react';

export const withOrientationCheck = (OriginalComponent) => {
  function OrientationCheck(props) {
    const [landscape, setLandscape] = useState(() => checkIfLandscape());

    useEffect(() => {
      const checkOrientation = () => {
        setLandscape(checkIfLandscape());
      };

      const listenOrientationChange = () => {
        if (window.screen.orientation) {
          window.screen.orientation.addEventListener('change', checkOrientation);
        } else {
          window.addEventListener('orientationchange', checkOrientation);
        }
      };

      const removeOrientationChangeListener = () => {
        if (window.screen.orientation) {
          window.screen.orientation.removeEventListener('change', checkOrientation);
        } else {
          window.removeEventListener('orientationchange', checkOrientation);
        }
      };

      listenOrientationChange();
      return () => {
        removeOrientationChangeListener();
      };
    }, []);

    return (
      <>
        {landscape && <Message landscape />}
        <OriginalComponent {...props} />
      </>
    );

    function checkIfLandscape() {
      let isLandscape;
      if (window.screen.orientation) {
        isLandscape = window.screen.orientation.type.includes('landscape');
      } else {
        isLandscape = window.orientation !== 0;
      }
      return isLandscape;
    }
  }

  return OrientationCheck;
};
