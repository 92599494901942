import React, { useState } from 'react';
import Page from '@lib/components/v2/Page';
import { DetailMatchContent } from '@components/Contents';
import { FormValidation } from 'calidation';
import { localizedString } from '@languages';
import FormModel from './DetailMatch.form';
import { setCookie } from '../../lib/Utils';
import classes from './DetailMatch.style.module.scss';
import APIs from '../../services/APIs';

const DetailMatch = () => {
  const [state, setState] = useState({
    name: '',
    reference: '',
    isProcessing: false,
    errorMsg: ''
  });

  const handleChange = (id, value) => {
    setState((state) => ({
      ...state,
      [id]: value
    }));
  };

  const handleConfirm = ({ isValid }) => {
    // Send Request to Verify
    if (isValid) {
      const params = { ...state };
      setState((state) => ({
        ...state,
        isProcessing: true
      }));
      APIs.checkDetailMatch(params).then(({ status, message, type, url }) => {
        setState((state) => ({
          ...state,
          isProcessing: false
        }));
        if (status === 'success') {
          if (type === 'complete') {
            setCookie('detailMatch', null, -1);
          } else {
            setCookie('detailMatch', 'yes', 1 / 24);
          }
          window.location.href = url;
        } else {
          setCookie('detailMatch', null, -1);
          setState((state) => ({
            ...state,
            errorMsg: message
          }));
        }
      });
    }
  };

  const { isProcessing, errorMsg } = state;
  const title = localizedString('detailMatch.detailMatchTitle');
  const buttons = [
    {
      label: isProcessing ? localizedString('loading') : localizedString('continue'),
      type: 'submit',
      loading: isProcessing,
      className: 'text-center'
    }
  ];

  return (
    <FormValidation
      className={classes.wrapper}
      onSubmit={handleConfirm}
      config={FormModel}
      initialValues={{ ...state }}
    >
      {({ errors, dirty, submitted }) => (
        <Page title={title} buttons={buttons}>
          <DetailMatchContent
            {...state}
            onChange={handleChange}
            errors={errors}
            dirty={dirty}
            submitted={submitted}
          />
          {!errors.name && !errors.reference && errorMsg && (
            <div className={classes.error}>{errorMsg}</div>
          )}
        </Page>
      )}
    </FormValidation>
  );
};

export default DetailMatch;
