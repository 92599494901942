/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { FormValidation } from 'calidation';
import Modal from '@lib/components/v2/Modal';
import { SendSMSContent } from '@components/Contents';
import { localizedString } from '@languages';
import { COUNTRY, SELECT_COUNTRIES } from '@spotMobileConfig';
import countries from '@data/countries.json';
import SMS from '../../services/SMS';
import FormModel from './SendSMS.form';

const SendSMS = ({ ipCountryCode, resendCount: resendCountProps = 0 }) => {
  const [phoneCode, setPhoneCode] = useState('64');
  const [phoneNumber, setPhoneNumber] = useState(document.body.dataset?.phone || '');

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [help, setHelp] = useState(false);

  const [resendCount, setResendCount] = useState(Number(resendCountProps) || 0);

  useEffect(() => {
    const country =
      !isEmpty(SELECT_COUNTRIES) && SELECT_COUNTRIES.includes(ipCountryCode)
        ? ipCountryCode
        : COUNTRY;

    const { phone_code = null } = document.body.dataset;
    let country_code_select = { name: 'Australia', dial_code: '+61', code: 'AU' };

    if (country) {
      const found = countries.find((eachCountry) => eachCountry.code === country);
      if (found) country_code_select = found;
    }

    if (phone_code) {
      const found = countries.find((country) => country.dial_code === phone_code);
      if (found) country_code_select = found;
    }

    setPhoneCode(country_code_select.dial_code);
  }, [ipCountryCode]);

  const handleSubmit = ({ isValid }) => {
    if (isValid) {
      setIsLoading(true);
      setError(null);
      setMessage(null);
      SMS.send({ phone_number: phoneNumber, phone_code: phoneCode })
        .then(({ success }) => {
          setIsLoading(false);
          if (success) {
            setMessage(localizedString('messageSentSuccessfully'));
            setResendCount(resendCount + 1);
          } else {
            setError(localizedString('errorSendingSms'));
          }
        })
        .catch(() => {
          setError(localizedString('refreshAndTryAgain'));
          setIsLoading(false);
        });
    }
  };

  const handleCancel = () => {
    const backurl = document.querySelector('body').getAttribute('data-backurl');
    if (backurl) {
      window.location.href = backurl;
    } else {
      window.history.back();
    }
  };

  const handleHelp = (e) => {
    e.preventDefault();
    setHelp(true);
  };

  const onPhoneCodeChange = (value) => setPhoneCode(value.dial_code);

  const onPhoneNumberChange = (value) => setPhoneNumber(value);

  const modalBtns = [
    {
      label: localizedString('ok'),
      onClick: () => {
        setError(null);
        setMessage(null);
        setHelp(false);
      }
    }
  ];

  return (
    <div>
      <Modal
        small
        heading=""
        success={error === null}
        isOpen={message !== null || error !== null}
        buttons={modalBtns}
      >
        {message && (
          <h3 style={{ textAlign: 'center' }} className="b-title">
            {localizedString('smsSentToMobilePleaseCheck1')}
            <br />
            <br />
            {localizedString('smsSentToMobilePleaseCheck2')}
          </h3>
        )}
      </Modal>
      <Modal small issue={null} isOpen={help} buttons={modalBtns}>
        <div style={{ textAlign: 'center' }}>{localizedString('pleaseCheckAndTryAgain')}</div>
      </Modal>
      <FormValidation onSubmit={handleSubmit} config={FormModel}>
        {({ errors, submitted }) => (
          <SendSMSContent
            phoneNumber={phoneNumber}
            phoneCode={phoneCode}
            resendCount={resendCount}
            onCancel={handleCancel}
            onPhoneCodeChange={onPhoneCodeChange}
            onPhoneNumberChange={onPhoneNumberChange}
            onHelp={handleHelp}
            isLoading={isLoading}
            errors={submitted ? errors : {}}
          />
        )}
      </FormValidation>
    </div>
  );
};

SendSMS.propTypes = {
  resendCount: PropTypes.number,
  ipCountryCode: PropTypes.string
};

export default connect(mapStateToProps, null)(SendSMS);

function mapStateToProps({ ipInfo }) {
  return {
    ipCountryCode: ipInfo.ipCountryCode
  };
}
