import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

const FullNameReviewContext = React.createContext({});

export const withFullNameReviewDetails = (OriginalComponent) => {
  function WithFullNameReviewProvider(props) {
    return (
      <FullNameReviewProvider>
        <OriginalComponent {...props} />
      </FullNameReviewProvider>
    );
  }

  return WithFullNameReviewProvider;
};

export const FullNameReviewProvider = ({ children }) => {
  const [shouldDisplayFullName, setShouldDisplayFullName] = useState(false);

  const value = useMemo(
    () => ({
      shouldDisplayFullName,
      setShouldDisplayFullName
    }),
    [shouldDisplayFullName, setShouldDisplayFullName]
  );

  return <FullNameReviewContext.Provider value={value}>{children}</FullNameReviewContext.Provider>;
};

FullNameReviewProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useFullNameContext = () => {
  const state = useContext(FullNameReviewContext);

  if (state === undefined) {
    throw new Error('Make sure useFullNameContext is used within a FullNameReviewContext.');
  }

  const { shouldDisplayFullName, setShouldDisplayFullName } = state;

  return { shouldDisplayFullName, setShouldDisplayFullName };
};

export const withUseFullNameContextHook = (Component) => {
  return function WrappedComponent(props) {
    const { shouldDisplayFullName, setShouldDisplayFullName } = useFullNameContext();
    return (
      <Component
        {...props}
        shouldDisplayFullName={shouldDisplayFullName}
        setShouldDisplayFullName={setShouldDisplayFullName}
      />
    );
  };
};
