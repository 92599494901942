import moment from 'moment';
import { toDocumentType } from '@lib/constants/documents';
import {
  FLOW_V2_ALLOW_EXPIRED_TYPES,
  FLOW_V2_ALLOWED_TYPES_WITH_EMPTY_EXPIRY,
  VOI_FLOW_V2_ALLOW_EXPIRED_TYPES,
  VOI_FLOW_V2_ALLOWED_TYPES_WITH_EMPTY_EXPIRY
} from '@spotMobileConfig';

export const isEligibleForPassportExpiredLogic = (appConfig, cardType, countryCode) => {
  if (appConfig && appConfig.acceptExpiredPassportUpto) {
    const eligibleCountries = Object.keys(appConfig.acceptExpiredPassportUpto);
    return cardType.match(/PASSPORT/i) && eligibleCountries.includes(countryCode);
  }
  return false;
};

export const isPassportExpiredBy2Years = (expiryDate, { appConfig, cardType, countryCode }) => {
  if (isEligibleForPassportExpiredLogic(appConfig, cardType, countryCode)) {
    const limitInYears = appConfig.acceptExpiredPassportUpto[countryCode];
    const enteredExpiry = moment(expiryDate, 'DD-MM-YYYY');
    const expiryDateLimit = moment(new Date()).subtract(limitInYears, 'years');
    return enteredExpiry.isBefore(expiryDateLimit, 'day');
  }

  return false;
};

export const isDocumentExpired = (expiryDate) => {
  const today = moment(new Date());
  const enteredExpiry = moment(expiryDate, 'DD-MM-YYYY');
  return enteredExpiry.isBefore(today, 'day');
};

export const isExpiredIdAllowed = ({ flowType, cardType }) => {
  const docType = toDocumentType(cardType) || cardType;
  if (flowType === 'FLOW_V2') {
    return (
      FLOW_V2_ALLOW_EXPIRED_TYPES.includes('ALL') || FLOW_V2_ALLOW_EXPIRED_TYPES.includes(docType)
    );
  }
  if (flowType === 'VOI_FLOW_V2') {
    return (
      VOI_FLOW_V2_ALLOW_EXPIRED_TYPES.includes('ALL') ||
      VOI_FLOW_V2_ALLOW_EXPIRED_TYPES.includes(docType)
    );
  }
  return false;
};

export const isEmptyExpiryDateAllowed = ({ flowType, cardType }) => {
  const docType = toDocumentType(cardType) || cardType;
  if (flowType === 'FLOW_V2') {
    return (
      FLOW_V2_ALLOWED_TYPES_WITH_EMPTY_EXPIRY.includes('ALL') ||
      FLOW_V2_ALLOWED_TYPES_WITH_EMPTY_EXPIRY.includes(docType)
    );
  }
  if (flowType === 'VOI_FLOW_V2') {
    return (
      VOI_FLOW_V2_ALLOWED_TYPES_WITH_EMPTY_EXPIRY.includes('ALL') ||
      VOI_FLOW_V2_ALLOWED_TYPES_WITH_EMPTY_EXPIRY.includes(docType)
    );
  }
  return false;
};
