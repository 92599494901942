import { localizedString } from '@languages';

export const short = () => ({
  homeAddress: {
    isRequired: {
      message: localizedString('pleaseEnterYourHomeAddress')
    }
  },
  isMatch: {
    isEqual: {
      message: localizedString('enterValidAddress'),
      value: true
    }
  }
});
