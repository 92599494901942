import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Page from '@lib/components/v2/Page';
import Animation, { useAnimationUrls } from '@components/Animation';
import { localizedString } from '@languages';
import parse from 'html-react-parser';
import { Title } from '@lib/components/v2/Title';
import APIs from '@services/APIs';
import classes from './CaptureTips.style.module.scss';

const CaptureTips = ({ type, onHide, onCaptureAgain, resetTables = 'yes' }) => {
  const { animationUrls } = useAnimationUrls();
  const [step, setStep] = useState(0);

  const tipTitle = localizedString(`captureTipsHeading${step + 1}`);
  const tipDescription = localizedString(`captureTipsDescription${type}${step + 1}`);

  const buttons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: () => {
        if (step === 0) {
          onHide();
        } else {
          setStep(step - 1);
        }
      },
      dataTestId: 'capturTips-backBtn'
    },
    {
      label: step === 2 ? localizedString('captureMyID') : localizedString('next'),
      onClick: () => {
        if (step === 2) {
          setStep(0);
          onCaptureAgain();
          APIs.status('selectId', { resetTables });
        } else {
          setStep(step + 1);
        }
      },
      dataTestId: 'capturTips-nxtBtn'
    }
  ];

  return (
    <Page buttons={buttons}>
      <div className={classes.wrapper}>
        <Title data-testid="capturTips-heading" title={tipTitle} />
        <div className={classes.description} data-testid="capturTips-txt1">
          {parse(tipDescription)}
        </div>
        <div className={classes['animation-container']} data-testid="capturTips-anime">
          {step === 0 && <Animation animationUrl={animationUrls.CLOSE_FAR} />}
          {step === 1 && <Animation animationUrl={animationUrls.LAPTOP} />}
          {step === 2 && <Animation animationUrl={animationUrls.LAMP} />}
        </div>
      </div>
    </Page>
  );
};

CaptureTips.propTypes = {
  type: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  onCaptureAgain: PropTypes.func.isRequired,
  resetTables: PropTypes.bool
};

export default CaptureTips;
