import React, { useEffect, useState } from 'react';
import { LOCATION, LOCATION_REQUIRED } from '@spotMobileConfig';
import { geoLocation } from '@lib/Utils';
import { EnableLocation, LocationSettings } from '@lib/components/v2/Capture/Capture.error';
import { localizedString } from '@languages';

const ERRORS = {
  LOCATION_SETTINGS: 'LOCATION_SETTINGS',
  ENABLE_LOCATION: 'ENABLE_LOCATION'
};

export const withGeolocation = (Component) => {
  return function WrappedComponent(props) {
    // eslint-disable-next-line react/prop-types
    const { onExit, onGeoLocation } = props;

    const [error, setError] = useState(null);
    useEffect(() => {
      if (LOCATION)
        geoLocation()
          .then((geolocation) => onGeoLocation(geolocation))
          .catch(() => {
            if (!LOCATION_REQUIRED) {
              onGeoLocation(null);
              return;
            }
            setError(ERRORS.ENABLE_LOCATION);
          });
    }, [onGeoLocation, onExit]);

    if (error === ERRORS.LOCATION_SETTINGS)
      return (
        <LocationSettings
          buttons={[
            {
              label: localizedString('exit'),
              variant: 'outline',
              onClick: () => onExit()
            },
            {
              label: localizedString('checkAgainLabel'),
              onClick: () => {
                geoLocation()
                  .then((geolocation) => {
                    onGeoLocation(geolocation);
                    setError(null);
                  })
                  .catch(() => null);
              }
            }
          ]}
        />
      );

    if (error === ERRORS.ENABLE_LOCATION)
      return (
        <EnableLocation
          buttons={[
            {
              label: localizedString('exit'),
              variant: 'outline',
              onClick: () => onExit()
            },
            {
              label: localizedString('enable'),
              onClick: () => setError(ERRORS.LOCATION_SETTINGS)
            }
          ]}
        />
      );

    return <Component {...props} />;
  };
};
