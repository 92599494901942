import { useAllowedDocumentsInIDVC } from '@js/hooks/useAllowedDocumentsInIDVC';
import { toDocumentType, ALL_CARD_TYPES } from '@lib/constants/documents';

import {
  VOI_FLOW_V2_AUS_AUTO_DRIVERLICENCE,
  VOI_FLOW_V2_PROOF_OF_AGE,
  VOI_FLOW_V2_AUS_MEDICARE,
  VOI_FLOW_V2_AUS_AUTO_BIRTH,
  VOI_FLOW_V2_AUS_CERT_CITIZENSHIP,
  VOI_FLOW_V2_AUS_CERT_DESCENT,
  VOI_FLOW_V2_AUS_CENTRELINK,
  VOI_FLOW_V2_AUS_DEFENCE_FORCE,
  VOI_FLOW_V2_AVAILABLE_DOCS1,
  VOI_FLOW_V2_AVAILABLE_DOCS2,
  VOI_FLOW_V2_ONE_DOC_CONDITION_DOCS1,
  VOI_FLOW_V2_ONE_DOC_CONDITION_DOCS2,
  ENABLE_ONE_DOC_CONDITION
} from '@spotMobileConfig';

const defaultAvailableDocumentListPart1 = [
  {
    type: 'Driver Licence',
    title: VOI_FLOW_V2_AUS_AUTO_DRIVERLICENCE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'PASSPORT',
    title: 'Passport',
    icon: 'icon_passport.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'AUS_IMMICARD',
    title: 'ImmiCard',
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'PROOF_OF_AGE',
    title: VOI_FLOW_V2_PROOF_OF_AGE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  }
];

const defaultAvailableDocumentListPart2 = [
  {
    type: 'Medicare',
    title: VOI_FLOW_V2_AUS_MEDICARE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'AUS_AUTO_BIRTH',
    title: VOI_FLOW_V2_AUS_AUTO_BIRTH,
    icon: 'icon_cert.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'CITIZENSHIP_CERT',
    title: VOI_FLOW_V2_AUS_CERT_CITIZENSHIP,
    icon: 'icon_cert.svg',
    checked: false,
    ocr: false
  },
  {
    type: 'AUS_CERT_DESCENT',
    title: VOI_FLOW_V2_AUS_CERT_DESCENT,
    icon: 'icon_cert.svg',
    checked: false,
    ocr: false
  },
  {
    type: 'Centrelink',
    title: VOI_FLOW_V2_AUS_CENTRELINK,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'Defence Force ID',
    title: VOI_FLOW_V2_AUS_DEFENCE_FORCE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  }
];

const defaultOneDocConditionDocumentList = [
  {
    type: 'Driver Licence',
    title: VOI_FLOW_V2_AUS_AUTO_DRIVERLICENCE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'PASSPORT',
    title: 'Passport',
    icon: 'icon_passport.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'PROOF_OF_AGE',
    title: VOI_FLOW_V2_PROOF_OF_AGE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  }
];

const defaultAdditionalOneDocConditionDocumentList = [
  {
    type: 'PASSPORT',
    title: 'Passport',
    icon: 'icon_passport.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'Medicare',
    title: VOI_FLOW_V2_AUS_MEDICARE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  },
  {
    type: 'Defence force I.D',
    title: VOI_FLOW_V2_AUS_DEFENCE_FORCE,
    icon: 'icon_card.svg',
    checked: false,
    ocr: true
  }
];

const availableDocumentListPart1 = VOI_FLOW_V2_AVAILABLE_DOCS1
  ? [...VOI_FLOW_V2_AVAILABLE_DOCS1]
  : [...defaultAvailableDocumentListPart1];

const availableDocumentListPart2 = VOI_FLOW_V2_AVAILABLE_DOCS2
  ? [...VOI_FLOW_V2_AVAILABLE_DOCS2]
  : [...defaultAvailableDocumentListPart2];

const oneDocConditionDocumentList =
  VOI_FLOW_V2_ONE_DOC_CONDITION_DOCS1 ?? defaultOneDocConditionDocumentList;

const oneDocConditionAdditionalDocumentList =
  VOI_FLOW_V2_ONE_DOC_CONDITION_DOCS2 ?? defaultAdditionalOneDocConditionDocumentList;

const getDocListPart1 = (currentDocument) => {
  if (ENABLE_ONE_DOC_CONDITION) {
    return currentDocument?.index < 1
      ? oneDocConditionDocumentList
      : oneDocConditionAdditionalDocumentList;
  }
  return availableDocumentListPart1;
};

export const useVoiV2DocListPart1 = (currentDocument) => {
  const { cardTypeObject } = useAllowedDocumentsInIDVC();

  const allowIdvcCardTypes = Object.keys(cardTypeObject);
  const allowIdvcDocTypes = allowIdvcCardTypes.map(toDocumentType);

  const acceptAllDocs = cardTypeObject[ALL_CARD_TYPES] !== undefined;

  return getDocListPart1(currentDocument).filter((docItem) => {
    return acceptAllDocs || allowIdvcDocTypes.includes(docItem.type);
  });
};

export const useVoiV2DocListPart2 = () => {
  const { cardTypeObject } = useAllowedDocumentsInIDVC();

  const allowIdvcCardTypes = Object.keys(cardTypeObject);
  const allowIdvcDocTypes = allowIdvcCardTypes.map(toDocumentType);
  const acceptAllDocs = cardTypeObject[ALL_CARD_TYPES] !== undefined;

  return availableDocumentListPart2.filter((docItem) => {
    return acceptAllDocs || allowIdvcDocTypes.includes(docItem.type);
  });
};
