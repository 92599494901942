import React from 'react';
import PropTypes from 'prop-types';
import { Validation } from 'calidation';
import classNames from 'classnames';
import { Title } from '@lib/components/v2/Title';
import { Select } from '@lib/components/v2/Form';
import { localizedString } from '@languages';
import classes from './MoreInfo.style.module.scss';

const MoreInfo = ({
  optionsDict,
  citizenship,
  onCitizenshipChange,
  occupation,
  onOccupationChange
}) => {
  const config = {
    citizenship: {
      isRequired: localizedString('chooseCitizenship')
    },
    occupation: {
      isRequired: localizedString('chooseOccupation')
    }
  };

  const citizenshipOptions = optionsDict.citizenship.map((item) => ({ value: item, label: item }));
  const occupationOptions = optionsDict.occupation.map((item) => ({ value: item, label: item }));

  return (
    <div className={classNames(classes.wrapper)}>
      <Title title="Please tell us more about you" />
      <div className={classNames(classes.description)}>This also helps verify your identity.</div>
      <div className={classNames(classes.container)}>
        <div>
          <div className={classNames(classes.subheading)}>Citizenship</div>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label className={classNames(classes.selectDescription)} id="label-citizenship">
            Select your country of citizenship
          </label>
          <div className={classNames(classes.select)}>
            <Validation config={config}>
              {() => {
                return (
                  <Select
                    id="citizenship"
                    name="citizenship"
                    aria-labelledby="label-citizenship"
                    className={classNames(classes.select)}
                    options={citizenshipOptions}
                    onChange={(value) => onCitizenshipChange(value)}
                    value={citizenship}
                  />
                );
              }}
            </Validation>
          </div>
        </div>
        <div>
          <div className={classNames(classes.subheading)}>Occupation</div>
          <label className={classNames(classes.selectDescription)} htmlFor="occupation">
            {localizedString('chooseOccupation')}
          </label>
          <div className={classNames(classes.select)}>
            <Validation config={config}>
              {() => {
                return (
                  <Select
                    id="occupation"
                    name="occupation"
                    label={localizedString('occupation')}
                    className={classNames(classes.select)}
                    options={occupationOptions}
                    onChange={(value) => onOccupationChange(value)}
                    value={occupation}
                  />
                );
              }}
            </Validation>
          </div>
        </div>
      </div>
    </div>
  );
};

MoreInfo.propTypes = {
  optionsDict: PropTypes.object,
  citizenship: PropTypes.object,
  occupation: PropTypes.object,
  onCitizenshipChange: PropTypes.func,
  onOccupationChange: PropTypes.func
};

export default MoreInfo;
