import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Input } from '@lib/components/v2/Form';
import ReactDatePicker from 'react-mobile-datepicker';
import {
  isAndroidDevice,
  dateToString,
  monthYearToString,
  reverseDateFormatSF,
  convertDateToUTC
} from '@lib/Utils';
import { localizedString } from '@languages';
import classes from './DatePicker.style.module.scss';

const pickerMenus = {
  'DD-MM-YYYY': {
    date: { format: 'D', caption: 'Day' },
    month: { format: 'M', caption: 'Month' },
    year: { format: 'YYYY', caption: 'Year' }
  },
  'MM-DD-YYYY': {
    month: { format: 'M', caption: 'Month' },
    date: { format: 'D', caption: 'Day' },
    year: { format: 'YYYY', caption: 'Year' }
  },
  'MM-YYYY': { month: { format: 'M', caption: 'Month' }, year: { format: 'YYYY', caption: 'Year' } }
};

const getUtcDateValue = (value) => convertDateToUTC(reverseDateFormatSF(value, true));

/**
 * A wrapper around `react-mobile-datepicker` with `Input` component.
 */
export default function DatePicker({
  value,
  onChange,
  className,
  hasError,
  disabled = false,
  displayedDateFormat,
  dataTestId,
  ...restProps
}) {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (date) => {
    const showMonthDateOnly = displayedDateFormat === 'MM-YYYY';
    const value = showMonthDateOnly ? monthYearToString(date) : dateToString(date, false);
    setIsOpen(false);
    onChange(value);
  };

  const theme = isAndroidDevice() ? 'android' : 'ios';
  const momentValue = moment(getUtcDateValue(value));
  const datePickerProps = {
    theme,
    isOpen,
    confirmText: localizedString('select'),
    cancelText: localizedString('cancel'),
    headerFormat: displayedDateFormat,
    min: new Date(1900, 1, 1),
    value: value && momentValue.isValid() ? getUtcDateValue(value) : undefined
  };

  const inputDateValue =
    value && momentValue.isValid() ? momentValue.format(displayedDateFormat) : undefined;

  const dateConfig = pickerMenus[displayedDateFormat];

  return (
    <div className={classes.wrapper}>
      <button
        type="button"
        className={`${className} ${classes.transparentButton}`}
        onClick={() => !disabled && setIsOpen(true)}
        data-testid={`${dataTestId}-button`}
      >
        <Input
          paddingLeft30
          readOnly
          hasError={hasError}
          value={inputDateValue}
          disabled={disabled}
          dataTestId={dataTestId}
          {...restProps}
        />
      </button>
      {!disabled && (
        <React.Fragment>
          <ReactDatePicker
            dateConfig={dateConfig}
            onSelect={handleSelect}
            onCancel={() => setIsOpen(false)}
            {...datePickerProps}
          />
          <button type="button" onClick={() => setIsOpen(true)} className={classes.icon}>
            <img alt="" src="images/icons/png/datepicker.png" />
          </button>
        </React.Fragment>
      )}
    </div>
  );
}

DatePicker.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  hasError: PropTypes.string,
  disabled: PropTypes.bool,
  displayedDateFormat: PropTypes.string,
  dataTestId: PropTypes.string
};

DatePicker.defaultProps = {
  className: '',
  value: '',
  name: '',
  id: '',
  label: '',
  onChange: () => null,
  displayedDateFormat: 'DD-MM-YYYY'
};
