import React from 'react';

export const UncheckedRadioIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-hidden="true"
    >
      <path
        d="M21.5 11C21.5 16.799 16.799 21.5 11 21.5C5.20101 21.5 0.5 16.799 0.500001 11C0.500001 5.20101 5.20101 0.500004 11 0.500005C16.799 0.500005 21.5 5.20101 21.5 11Z"
        stroke="#A4A4A4"
      />
    </svg>
  );
};
