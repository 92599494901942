import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Title } from '@lib/components/v2/Title';
import Page from '@lib/components/v2/Page';
import { localizedString } from '@languages';
import { withAnimationUrls } from '@components/Animation';
import { ConfirmModal } from '@lib/components/v2/ConfirmModal';
import { DocNotAccepted } from '@lib/components/v2/VerifyDetails/VerifyDetails.errors';
import parse from 'html-react-parser';

import {
  VOI_FLOW_V2_NAME_MATCH_DOCS,
  VOI_FLOW_V2_NAME_MATCH_SHOW_SKIP_THIS_STEP
} from '@spotMobileConfig';

import { Radio } from '@lib/components/v2/Form';
import { isValidImageFile } from '@lib/validations/file';
import classes from './NameMatch.style.module.scss';

export const defaultNameMatchDocs = [
  {
    type: 'AUS_AUTO_NAME',
    title: 'Change of name certificate',
    checked: true,
    ocr: false,
    nextAfterOcr: { step: 2 }
  },
  {
    type: 'AUS_AUTO_MARRIAGE',
    title: 'Marriage certificate',
    checked: false,
    ocr: false,
    nextAfterOcr: { step: 2 }
    // nextAfterOcr: {showCaptureExtra: true}
  }
];

const defaultOptionList = VOI_FLOW_V2_NAME_MATCH_DOCS
  ? [...VOI_FLOW_V2_NAME_MATCH_DOCS]
  : [...defaultNameMatchDocs];

if (VOI_FLOW_V2_NAME_MATCH_SHOW_SKIP_THIS_STEP) {
  defaultOptionList.push({
    type: 'SKIP',
    title: localizedString('nameMatch.skipThisStep'),
    checked: false
  });
}

const NameMatch = ({
  onGoBack,
  onNextStep,
  frontParams,
  flowType,
  backOfCard,
  onUpdateSelectedDocList
}) => {
  const [optionList, setOptionList] = useState(defaultOptionList);
  const [isExitConfirmModalDisplayed, setIsExitConfirmModalDisplayed] = useState(false);
  const [error, setError] = useState(null);

  const elementRef = useRef();

  async function handleCapture(e) {
    const imageFile = e.target.files[0];
    if (!isValidImageFile(imageFile)) {
      setError({
        component: DocNotAccepted,
        props: {
          buttons: [
            {
              label: localizedString('tryAgain'),
              onClick: () => setError(null)
            }
          ]
        }
      });
      return;
    }

    e.target.value = '';

    const selected = optionList.find((opt) => opt.checked);

    if (!selected) {
      console.error('Please select an option');
    }

    let params;
    if (backOfCard) {
      params = {
        ...frontParams,
        backFile: imageFile,
        flowType,
        ocr: selected.ocr,
        idType: selected.type
      };
    } else {
      params = { frontFile: imageFile, flowType, ocr: selected.ocr, idType: selected.type };
    }

    onUpdateSelectedDocList({ ...selected, params });
  }

  function handleNextStep(e) {
    if (e) {
      e.preventDefault();
    }

    const selectedItem = optionList.find((item) => item.checked);

    if (selectedItem.type === 'SKIP') {
      onNextStep({ step: 2, showNameMatch: false });
    } else {
      elementRef.current?.click();
    }
  }

  function onOptionClick(item, index) {
    if (item.checked) {
      return;
    }

    setOptionList((list) => {
      const optionList = [...list];
      for (let i = 0; i < optionList.length; i++) {
        optionList[i].checked = index === i;
      }
      return optionList;
    });
  }

  const { component: Error, props: errorProps } = error || {};
  if (Error) {
    return <Error {...errorProps} />;
  }

  const footerButtons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: () => setIsExitConfirmModalDisplayed(true)
    },
    {
      label: localizedString('continue'),
      type: 'submit',
      onClick: () => handleNextStep(),
      dataTestId: 'chngNm-continue'
    }
  ];

  return (
    <div>
      <input
        type="file"
        name="image"
        accept="image/*"
        capture="environment"
        onChange={handleCapture}
        ref={elementRef}
        style={{ opacity: 0, zIindex: 99 }}
        aria-hidden="true"
      />
      <Page buttons={footerButtons}>
        {isExitConfirmModalDisplayed && (
          <ConfirmModal
            heading={localizedString('app.FLOW_V2_EXIT_SCREEN_TITLE')}
            onConfirm={onGoBack}
            onCancel={() => setIsExitConfirmModalDisplayed(false)}
          >
            {localizedString('app.FLOW_V2_EXIT_SCREEN_DESCRIPTION_DETAILS')}
          </ConfirmModal>
        )}
        <div className={classes.wrapper}>
          <Title
            data-testid="chngNm-heading"
            title={localizedString('app.VOI_FLOW_V2_NAME_MATCH_HEADING')}
          />
          <div className={classes.description} data-testid="chngNm-instruction">
            {parse(localizedString('app.VOI_FLOW_V2_NAME_MATCH_DESCRIPTION'))}
          </div>
          <div className={classes.option_list}>
            {optionList.map((item, index) => (
              <Radio
                key={item.title}
                onChange={() => onOptionClick(item, index)}
                checked={item.checked}
                title={item.title}
                id={`chngNm-docType-${index}`}
                data-testid={`chngNm-docType-${index}`}
                className={classNames(classes.option_item, item.checked && classes.checked)}
              />
            ))}
          </div>
        </div>
      </Page>
    </div>
  );
};

NameMatch.propTypes = {
  onGoBack: PropTypes.func,
  onNextStep: PropTypes.func,
  frontParams: PropTypes.object,
  flowType: PropTypes.string,
  backOfCard: PropTypes.bool,
  onUpdateSelectedDocList: PropTypes.func
};

export default withAnimationUrls(NameMatch);
