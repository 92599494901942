import { useCallback, useEffect, useState } from 'react';
import APIs from '@services/APIs';

export function useLivenessSession() {
  const defaultErrorMesage = 'Error getting liveness tokens';
  const [sessionId, setSessionId] = useState();
  const [txnId, setTxnId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const fetchLivenessSession = useCallback(async () => {
    setIsLoading(true);
    try {
      // eslint-disable-next-line camelcase
      const { status, tx_id, session_id, message } = await APIs.createLivenessSession();
      if (status !== 'success') {
        throw message || defaultErrorMesage;
      }

      setSessionId(session_id);
      setTxnId(tx_id);
      setErrorMessage(null);
    } catch (error) {
      setErrorMessage(error?.message || error || defaultErrorMesage);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchLivenessSession();
  }, []);

  return { sessionId, txnId, errorMessage, isLoading, fetchLivenessSession };
}
