import PropTypes from 'prop-types';
import Animation from '@components/Animation';
import { Title } from '@lib/components/v2/Title';
import classes from './SimplePageContent.style.module.scss';

export const SimplePageContent = ({ title, description, animationUrl }) => {
  return (
    <div className={classes.wrapper}>
      <Title title={title} />
      <div className={classes.description}>{description}</div>
      <div className={classes['animation-container']}>
        <Animation scale={1.2} animationUrl={animationUrl} />
      </div>
    </div>
  );
};

SimplePageContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  animationUrl: PropTypes.string
};
