import React, { useState } from 'react';
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_COUNTRY_LABEL,
  FLOW_V2_ACCEPTABLE_DOCS_IP_COUNTRY_SELECTED
} from '@spotMobileConfig';
import { getCountryLabelFromIso2Code } from '@lib/countryUtils';
import iso3 from '@data/iso3.json';
import { useAllowedDocumentsInIDVC } from '@js/hooks/useAllowedDocumentsInIDVC';
import Page from '@lib/components/v2/Page';
import { localizedString } from '@languages';
import classes from '@FLOW_V2_FLOW/components/Contents/IdSelection/IdSelection.style.module.scss';
import { Title } from '@lib/components/v2/Title';
import { CountrySelect } from '@lib/components/v2/Form';
import PropTypes from 'prop-types';

export const AcceptableDocs = ({
  engine4Config = {},
  ipCountryCode = '',
  onBack,
  onClickedFooterTakePhoto
}) => {
  const { acceptedCountries } = engine4Config;

  const [country, setCountry] = useState(() => {
    if (FLOW_V2_ACCEPTABLE_DOCS_IP_COUNTRY_SELECTED && ipCountryCode) {
      return {
        value: ipCountryCode,
        label: getCountryLabelFromIso2Code(ipCountryCode)
      };
    }

    return {
      value: DEFAULT_COUNTRY_CODE,
      label: DEFAULT_COUNTRY_LABEL
    };
  });

  const iso3Country = iso3[country.value];
  const { cardTypeObject } = useAllowedDocumentsInIDVC({
    iso3Country,
    omitDDL: true
  });

  const handleCountryChange = (val) => {
    setCountry(val);
  };

  return (
    <Page
      buttons={[
        {
          label: localizedString('back'),
          variant: 'transparent',
          onClick: () => onBack(),
          dataTestId: 'cptrId-back'
        },
        {
          label: localizedString('captureMyID'),
          type: 'submit',
          onClick: () => onClickedFooterTakePhoto(),
          dataTestId: 'cptrId-btn'
        }
      ]}
      forceFillViewPort
    >
      <div className={classes.wrapper}>
        <Title data-testid="accepted-heading" title={localizedString('acceptedIdDocs')} />
        <div aria-atomic="true" aria-live="assertive">
          <CountrySelect
            filter={acceptedCountries}
            value={country}
            onChange={handleCountryChange}
            label={localizedString('idSelection.FLOW_V2_COUNTRY_LABEL')}
          />
          <div className={classes.accepted}>
            <div role="list">
              {Object.keys(cardTypeObject).map((card, index) => (
                <div key={card} role="listitem" data-testid={`accepted-doc${index}`}>
                  <span aria-hidden>&#x2022; </span> {cardTypeObject[card].label}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

AcceptableDocs.propTypes = {
  engine4Config: PropTypes.object,
  ipCountryCode: PropTypes.string,
  onBack: PropTypes.func,
  onClickedFooterTakePhoto: PropTypes.func
};
