/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup, Input } from '@lib/components/v2/Form';

import Select, { components } from 'react-select';
import { localizedString } from '@languages';
import { NoMobileDeviceWrapper } from '@layouts/NoMobileDeviceWrapper';
import { DISABLE_COUNTRY_SELECT, SELECT_COUNTRIES, SEND_SMS_BACK_BUTTON } from '@spotMobileConfig';

import countries from '../../../data/countries.json';

import classes from './SendSMS.style.module.scss';

const SingleValue = ({ children, ...props }) => {
  return <components.SingleValue {...props}>{props.data.dial_code}</components.SingleValue>;
};

SingleValue.propTypes = {
  children: PropTypes.node,
  data: PropTypes.object
};

const Menu = (props) => {
  const { children } = props;
  return (
    <components.Menu {...props}>
      <div className="select-options">{children}</div>
    </components.Menu>
  );
};

Menu.propTypes = {
  children: PropTypes.node
};

const Inputs = (props) => {
  return <components.Input {...props} className="select-input" />;
};
const Option = (props) => {
  const { children } = props;
  return (
    <components.Option {...props} className="option">
      {children}
    </components.Option>
  );
};

Option.propTypes = {
  children: PropTypes.node
};

const SendSMS = ({
  phoneCode,
  phoneNumber,
  onPhoneCodeChange,
  onPhoneNumberChange,
  errors,
  onCancel,
  onHelp,
  isLoading,
  resendCount
}) => {
  let filteredCountries = countries;
  if (SELECT_COUNTRIES.length > 0) {
    filteredCountries = countries.filter((country) => {
      return SELECT_COUNTRIES.includes(country.code);
    });
  }

  const countryCode = filteredCountries.find((country) => country.dial_code === phoneCode);

  return (
    <NoMobileDeviceWrapper>
      {resendCount < 3 && (
        <>
          <p>
            <b>{localizedString('weNeedToUseYourMobile')}</b>
            <br />
            <br />
            {localizedString('enterMobileNumberAndSend1')}
            <br />
            {localizedString('enterMobileNumberAndSend2')}
            <br />
            <br />
            <Button variant="link" type="button" onClick={onHelp} className={classes.link}>
              {localizedString('notReceivedMessage')}
            </Button>
          </p>

          <div className={classes.input}>
            <div className={classes.code}>
              <Select
                isDisabled={DISABLE_COUNTRY_SELECT}
                options={filteredCountries}
                onChange={onPhoneCodeChange}
                styles={{
                  control: (base, state) => ({
                    ...base,
                    border: `2px solid #c7c7c7`,
                    paddingBottom: '2px',
                    borderTop: 'none',
                    borderLeft: 'none',
                    borderRight: 'none',
                    // This line disable the blue border
                    boxShadow: state.isFocused ? 0 : 0,
                    '&:hover': {
                      // border: state.isFocused ? 0 : 0
                    }
                  })
                }}
                components={{ SingleValue, Menu, Input: Inputs, Option }}
                value={countryCode}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: '0',
                  colors: {
                    ...theme.colors
                    // primary: '#5b8f23',
                  }
                })}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.dial_code}
                className="country_code_desktop"
              />
            </div>
            <div className={classes.number}>
              <Input
                id="phone_number"
                value={phoneNumber}
                hasError={errors.phone_number}
                placeholder="000 000 000"
                onChange={onPhoneNumberChange}
              />
            </div>
          </div>

          {SEND_SMS_BACK_BUTTON && (
            <ButtonGroup stacked btnCount="1">
              <Button label={localizedString('doneGoBack')} variant="outline" onClick={onCancel} />{' '}
              <Button loading={isLoading} type="submit" label={localizedString('send')} />
            </ButtonGroup>
          )}

          {!SEND_SMS_BACK_BUTTON && (
            <ButtonGroup>
              <Button full loading={isLoading} type="submit" label={localizedString('send')} />
            </ButtonGroup>
          )}
        </>
      )}

      {resendCount >= 3 && (
        <p>
          <h4>{localizedString('exceedAmountOfSMSRetries')}</h4>
          {localizedString('checkYourSMSMessages1')} <br />
          {localizedString('checkYourSMSMessages2')}
        </p>
      )}
    </NoMobileDeviceWrapper>
  );
};

SendSMS.propTypes = {
  errors: PropTypes.object,
  onCancel: PropTypes.func,
  onHelp: PropTypes.func,
  onPhoneCodeChange: PropTypes.func,
  onPhoneNumberChange: PropTypes.func,
  isLoading: PropTypes.bool,
  phoneNumber: PropTypes.string,
  phoneCode: PropTypes.string,
  resendCount: PropTypes.number
};

SendSMS.defaultProps = {
  onCancel: () => null,
  onPhoneCodeChange: () => null,
  onPhoneNumberChange: () => null,
  onHelp: () => null,
  resendCount: 0
};

export default SendSMS;
