import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ENABLE_ROUND_BUTTON } from '@spotMobileConfig';
import { localizedString } from '@languages';

import classes from './Button.style.module.scss';

/**
 * A sample button.
 */
const Button = ({
  label,
  children,
  type = 'button',
  shadow,
  large,
  full,
  variant,
  className,
  loading,
  onClick,
  disabled,
  rounded,
  style,
  dataTestId,
  ...rest
}) => {
  const isBackButton = label === localizedString('back') || label.toLowerCase?.() === 'back';
  return (
    <button
      style={style}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={disabled}
      aria-disabled={disabled ? 'true' : undefined}
      className={classNames(
        'btn-override',
        `btn-${variant}-override`,
        classes.btn,
        className,
        ENABLE_ROUND_BUTTON && rounded && classes.rounded,
        shadow && classes.shadow,
        large && classes.large,
        full && classes.full,
        loading && classes.right,
        isBackButton && classes.back,
        classes[variant]
      )}
      data-testid={dataTestId}
      role={loading ? 'alert' : undefined}
      aria-label={loading && !(label || children) ? localizedString('loading') : undefined}
      {...rest}
    >
      {isBackButton && (
        <img
          className={classes['img-back']}
          alt=""
          src="images/icons/png/back-videoid.png"
          data-testid="button-back-img"
          aria-hidden="true"
        />
      )}
      {/* loading ? 'Loading' : */ label || children}
      {loading && (
        <span className={classes.spinner} data-testid="button-loading-spinner">
          {/* <img alt="" src={spinner} /> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0,0 200,200"
            role="img"
            aria-hidden="true"
          >
            <defs>
              <clipPath id="ring">
                <path
                  d="M 200, 100
                     A 100, 100, 0, 1, 1, 197.81, 79.21
                     L 188.03, 81.29
                     A 90, 90, 0, 1, 0, 190, 100 z"
                />
              </clipPath>
              <filter id="blur" x="0" y="0">
                <feGaussianBlur in="SourceGraphic" stdDeviation="3" />
              </filter>
              <path
                id="p"
                className={classes.svg}
                d="M 250, 100
                        A 150, 150, 0, 0, 1, 246.72, 131.19
                        L 100, 100
                        A 0, 0, 0, 0, 0, 100, 100 z"
              />
            </defs>
            <g clipPath="url(#ring)">
              <g filter="url(#blur)" transform="rotate(-6 100 100)">
                <use xlinkHref="#p" fillOpacity="0" transform="rotate(  0 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.03" transform="rotate( 12 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.07" transform="rotate( 24 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.1" transform="rotate( 36 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.14" transform="rotate( 48 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.17" transform="rotate( 60 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.2" transform="rotate( 72 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.24" transform="rotate( 84 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.28" transform="rotate( 96 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.31" transform="rotate(108 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.34" transform="rotate(120 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.38" transform="rotate(132 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.41" transform="rotate(144 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.45" transform="rotate(156 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.48" transform="rotate(168 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.52" transform="rotate(180 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.55" transform="rotate(192 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.59" transform="rotate(204 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.62" transform="rotate(216 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.66" transform="rotate(228 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.69" transform="rotate(240 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.7" transform="rotate(252 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.72" transform="rotate(264 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.76" transform="rotate(276 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.79" transform="rotate(288 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.83" transform="rotate(300 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.86" transform="rotate(312 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.93" transform="rotate(324 100 100)" />
                <use xlinkHref="#p" fillOpacity="0.97" transform="rotate(336 100 100)" />
                <use xlinkHref="#p" fillOpacity="1" transform="rotate(348 100 100)" />
              </g>
            </g>
          </svg>
        </span>
      )}
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  type: PropTypes.string,
  shadow: PropTypes.bool,
  rounded: PropTypes.bool,
  /** Large Button */
  large: PropTypes.bool,
  /** Full with */
  full: PropTypes.bool,
  /** Use 'outline' or 'solid'. Other values are deprecated */
  variant: PropTypes.oneOf(['outline', 'primary', 'secandary', 'transparent', 'link']),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  dataTestId: PropTypes.string
};

Button.defaultProps = {
  label: '',
  type: 'button',
  shadow: false,
  large: false,
  full: false,
  variant: 'secandary',
  className: '',
  loading: false,
  rounded: true,
  style: {},
  onClick: () => null
};

export default Button;
