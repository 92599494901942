import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CaptureAction from '@store/actions/capture';
import Page from '@lib/components/v2/Page';
import { localizedString } from '@languages';
import { CaptureNSWDigitalDriverLicenceScreen } from '@lib/pages/v2/CaptureNSWDigitalDriverLicenceScreen';
import { DOCUMENTS } from '@lib/constants/documents';
import { DocNotAccepted } from '@lib/components/v2/VerifyDetails/VerifyDetails.errors';

import { ENABLE_ONE_DOC_CONDITION } from '@spotMobileConfig';
import { BackOfCard } from '@lib/components/v2/Contents';
import { Error500 } from '@lib/components/v2/errors';
import { compose } from 'redux';
import { withGeolocation } from '@lib/components/v2/Capture/Geolocation';
import { withCameraPermission } from '@lib/components/v2/Capture/CameraPermission';
import { isValidImageFile } from '@lib/validations/file';
import { IdSelectionContent } from '../../components/Contents';

const ERRORS = {
  UNEXPECTED_ERROR: 'UNEXPECTED_ERROR',
  NOT_ACCEPTED: 'NOT_ACCEPTED'
};

const Capture = ({
  onNextStep,
  onGoBack,
  selectedDocumentList,
  flowType,
  onCaptured,
  setFrontIDParams,
  isCameraEnabled,
  checkCameraPermission
}) => {
  const [error, setError] = useState(null);
  const elementRef = useRef(null);

  const reset = () => {
    setError(null);
  };

  const takePhoto = () => {
    elementRef.current.click();
  };

  const handleGoBack = (e) => {
    e?.preventDefault?.();

    const capturedDocumentList = selectedDocumentList.filter((doc) => doc.captured);
    const lastCard =
      capturedDocumentList.length > 0
        ? capturedDocumentList[capturedDocumentList.length - 1]
        : selectedDocumentList.find((card) => card.backOfCard);

    if (lastCard) {
      reset();
      onGoBack(lastCard);
    } else {
      onGoBack();
    }
  };

  const needToCaptureFront = (doc) => !doc.captured && !doc.backOfCard;
  const needToCaptureBack = (doc) => !doc.captured && doc.backOfCard;
  const currentDoc =
    selectedDocumentList.find(needToCaptureFront) || selectedDocumentList.find(needToCaptureBack);

  const showCaptureDigitalIdScreen =
    currentDoc?.type === DOCUMENTS.DIGITAL_DRIVER_LICENCE.documentType;

  const handleCaptureDoc = (currentDoc) => (e) => {
    const imageFile = e.target.files[0];
    if (!isValidImageFile(imageFile)) {
      setError(ERRORS.NOT_ACCEPTED);
      return;
    }

    e.target.value = '';
    captureFile(imageFile, currentDoc);
  };

  const onClickedFooterTakePhoto = () => {
    checkCameraPermission({ withError: true });
    if (!isCameraEnabled) return;
    takePhoto();
  };

  function captureFile(imagefile, currentDoc) {
    let params;
    if (currentDoc.backOfCard) {
      params = { backFile: imagefile, ...currentDoc.params };
    } else {
      params = { frontFile: imagefile, flowType, ocr: currentDoc.ocr };
    }

    onCaptured({ ...currentDoc, params });

    const pendingIDs = selectedDocumentList.filter(
      (doc) => !doc.captured && currentDoc.type !== doc.type
    );

    if (pendingIDs.length === 0) {
      onNextStep({ step: 1 });
    } else {
      reset();
    }
  }

  if (error === ERRORS.UNEXPECTED_ERROR)
    return (
      <Error500
        onTryAgain={() => {
          setFrontIDParams({});
          onGoBack();
        }}
      />
    );

  if (error === ERRORS.NOT_ACCEPTED) {
    return (
      <DocNotAccepted
        buttons={[
          {
            label: localizedString('tryAgain'),
            onClick: () => {
              setFrontIDParams({});
              setError(null);
            }
          }
        ]}
      />
    );
  }

  const footerButtons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: handleGoBack,
      dataTestId: 'docs-back'
    }
  ];
  if (isCameraEnabled === undefined) {
    footerButtons.push({
      label: localizedString('loading'),
      variant: 'transparent',
      loading: true,
      dataTestId: 'docs-uploading'
    });
  } else {
    let captureLabel = localizedString('captureMyID');
    if (ENABLE_ONE_DOC_CONDITION) {
      captureLabel = 'Capture my I.D';
    }

    footerButtons.push({
      label: captureLabel,
      type: 'submit',
      onClick: () => onClickedFooterTakePhoto(),
      dataTestId: 'docs-capture'
    });
  }

  return (
    <div>
      <input
        type="file"
        name="image"
        accept="image/*"
        capture="environment"
        onChange={handleCaptureDoc(currentDoc)}
        ref={elementRef}
        style={{ opacity: 0, zIndex: 99 }}
        aria-hidden="true"
      />
      {showCaptureDigitalIdScreen && (
        <CaptureNSWDigitalDriverLicenceScreen
          onUploadDigitalImage={({ imageFile }) => captureFile(imageFile, currentDoc)}
          onCloseScreen={handleGoBack}
        />
      )}
      {!showCaptureDigitalIdScreen && currentDoc && (
        <Page buttons={footerButtons} forceFillViewPort>
          {currentDoc.backOfCard && <BackOfCard idType={currentDoc.type} onReady={takePhoto} />}
          {!currentDoc.backOfCard && <IdSelectionContent selectedCard={currentDoc} />}
        </Page>
      )}
    </div>
  );
};

Capture.propTypes = {
  onNextStep: PropTypes.func,
  onGoBack: PropTypes.func,
  selectedDocumentList: PropTypes.array,
  flowType: PropTypes.string,
  onCaptured: PropTypes.func,
  setFrontIDParams: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types
  onExit: PropTypes.func, // for withGeolocation
  // eslint-disable-next-line react/no-unused-prop-types
  onGeoLocation: PropTypes.func, // for withGeolocation
  isCameraEnabled: PropTypes.bool,
  checkCameraPermission: PropTypes.func
};

function mapStateToProps({ capture }) {
  return {
    frontParams: capture.frontParams
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data))
  };
}

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withGeolocation,
  withCameraPermission
)(Capture);
