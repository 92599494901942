import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Page from '@lib/components/v2/Page';
import Modal from '@lib/components/v2/Modal';
import { Form } from 'calidation';
import { localizedString } from '@languages';
import { Error500 } from '@lib/components/v2/errors';

import APIs from '../../../services/APIs';

import { MoreInfoContent } from '../../components/Contents';

import anzData from '../../../data/anz-fields.json';

const MoreInfo = ({ onNextStep, on500Error, onGoBack }) => {
  const [citizenship, setCitizenship] = useState({ value: 'Australia', label: 'Australia' });
  const [occupation, setOccupation] = useState('');

  const [loading, setLoading] = useState(false);
  const [fatalError, setFatalError] = useState(null);
  const [error, setError] = useState(null);

  const handleConfirm = () => {
    setLoading(true);
    const data = { citizenship: citizenship.value, occupation: occupation.value };

    APIs.storeMoreInfo(data)
      .then(({ status, msg }) => {
        setLoading(false);
        if (status !== 'success') {
          setError({
            heading: msg,
            buttons: [
              {
                label: localizedString('cancel'),
                onClick: () => setError(null)
              }
            ]
          });
          return;
        }
        onNextStep();
      })
      .catch(({ message }) => {
        console.error(message);
        const fatalError = { component: Error500, props: { onTryAgain: on500Error } };
        setFatalError(fatalError);
        setLoading(false);
      });
  };

  const { component: Error, props: errorProps } = fatalError || {};

  const buttons = [
    {
      label: localizedString('back'),
      variant: 'transparent',
      onClick: onGoBack
    },
    {
      label: localizedString('Continue'),
      type: 'submit',
      disabled: !citizenship || !occupation,
      variant: loading ? 'transparent' : 'secandary',
      loading
    }
  ];

  return (
    <div>
      {Error && <Error {...errorProps} />}
      {error && <Modal isOpen {...error} />}
      <Form onSubmit={handleConfirm}>
        <Page
          title={localizedString('thisAlsoHelpsVerifyYourIdentity')}
          buttons={buttons}
          onGoBack={onGoBack}
        >
          <MoreInfoContent
            optionsDict={anzData}
            citizenship={citizenship}
            onCitizenshipChange={setCitizenship}
            occupation={occupation}
            onOccupationChange={setOccupation}
          />
        </Page>
      </Form>
    </div>
  );
};

MoreInfo.propTypes = {
  onNextStep: PropTypes.func,
  on500Error: PropTypes.func,
  onGoBack: PropTypes.func
};

export default connect(mapStateToProps, null)(MoreInfo);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ appConfig }) {
  return {
    appConfig
  };
}
