import { setCookie } from '@lib/Utils';

export const cleanAppCookies = () => {
  setCookie('retryAsf', 'no', -10);
  setCookie('retry', null, -7);
  setCookie('retryCaptureWithVideoStream', null, -7);
  setCookie('retryAttempt', null, -7);
  setCookie('idCaptureAttempt', 0, -7);
  setCookie('detailMatch', 'false', -1);
  setCookie('_permission', null, -7);
};
