import React, { useState } from 'react';
import CaptureVisaP from '@lib/components/v2/CaptureVisa';
import APIs from '@services/APIs';
import { Error500 } from '@lib/components/v2/errors';
import PropTypes from 'prop-types';
import { Loading } from '@FLOW_V2_FLOW/components/Contents/IdSelection/Loading';
import { CaptureBase } from '@FLOW_V2_FLOW/containers/Capture/CaptureBase';

export const CaptureVisa = ({
  idType,
  onSkip,
  setFrontIDParams,
  onNextStep,
  onGoBack,
  flowType
}) => {
  const [progressBar, setProgressBar] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);

  const uploadVisaImage = (imagefile) => {
    APIs.status('capturingId');
    const params = {
      visa: 'yes',
      idType: 'VISA',
      flowType,
      frontFile: imagefile
    };

    setIsUploading(true);
    setProgressBar(0);

    return APIs.uploadImage(params, {
      before: () => setProgressBar(0),
      onProgress: (width) => setProgressBar(width)
    })
      .then(({ status, msg: error, skip = false }) => {
        if (status === 'error') {
          setError(error);
          setIsUploading(false);
          if (skip) {
            setFrontIDParams({});
            onNextStep();
          }
          return;
        }

        setIsUploading(false);

        setFrontIDParams({});
        onNextStep();
      })
      .catch(() => {
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              setFrontIDParams({});
              onGoBack();
            }
          }
        };
        setError(error);
        setIsUploading(false);
      });
  };

  const { component: Error, props: errorProps } = error || {};
  if (Error) {
    return <Error {...errorProps} />;
  }

  if (isUploading) {
    return <Loading progressBar={progressBar} isUploading={isUploading} />;
  }

  return (
    <CaptureBase uploadImage={uploadVisaImage}>
      {({ capture }) => (
        <CaptureVisaP
          onSkip={onSkip}
          onUploadVisa={() => {
            if (idType) capture();
          }}
        />
      )}
    </CaptureBase>
  );
};

CaptureVisa.propTypes = {
  idType: PropTypes.string,
  flowType: PropTypes.string,
  setFrontIDParams: PropTypes.func,
  onSkip: PropTypes.func,
  onNextStep: PropTypes.func,
  onGoBack: PropTypes.func,
  verifyDetails: PropTypes.object
};
