import omit from 'lodash/omit';

const manualFields = [
  'country',
  'addressLine1',
  'addressLine2',
  'suburb',
  'postcode',
  'state_territory'
];

export const hasAdressBeingEdited = ({ dirty, fields }) => {
  return (
    dirty?.homeAddress || Object.keys(fields).some((fieldKey) => manualFields.includes(fieldKey))
  );
};

export const calculateEditedInfo = ({ extracted, fields, dirty, hasAddressBeenAlreadyChanged }) => {
  const changedFields = Object.keys(
    omit(fields, ['homeAddress', 'addressData', 'tooManyFieldsEdited', 'isMatch', ...manualFields])
  ).reduce((result, fieldKey) => {
    const currentValue = fields[fieldKey] || '';
    const extractedValue = extracted?.[fieldKey] || '';
    if (
      (currentValue.toLowerCase?.() || currentValue) !==
      (extractedValue.toLowerCase?.() || extractedValue)
    ) {
      result.push(fieldKey);
    }
    return result;
  }, []);

  const editedCount = changedFields.length;

  if (hasAddressBeenAlreadyChanged || hasAdressBeingEdited({ dirty, fields })) {
    changedFields.push('addressData');
  }

  return { editedCount, changedFields };
};
