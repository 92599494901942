import React, { useEffect, useState } from 'react';
import { checkIfCameraEnabled } from '@lib/Utils';
import { Permission } from '@lib/components/v2/Capture/Capture.error';
import { localizedString } from '@languages';

export const withCameraPermission = (Component) => {
  return function WrappedComponent(props) {
    const [isCameraEnabled, setIsCameraEnabled] = useState(undefined);
    const [error, setError] = useState(false);

    const checkCameraPermission = async ({ withError } = {}) => {
      const isCameraEnabled = await checkIfCameraEnabled();
      setIsCameraEnabled(isCameraEnabled);
      if (withError && !isCameraEnabled) {
        setError(true);
      }
      return isCameraEnabled;
    };

    useEffect(() => {
      checkCameraPermission();

      const handleVisibilityChange = () => checkCameraPermission();
      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    }, []);

    if (error) {
      return (
        <Permission
          buttons={[
            {
              label: localizedString('back'),
              variant: 'transparent',
              onClick: () => setError(false)
            },
            {
              label: localizedString('tryAgain'),
              onClick: () => setError(false)
            }
          ]}
        />
      );
    }

    return (
      <Component
        {...props}
        isCameraEnabled={isCameraEnabled}
        checkCameraPermission={checkCameraPermission}
      />
    );
  };
};
