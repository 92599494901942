import PropTypes from 'prop-types';
import { useState } from 'react';
import parse from 'html-react-parser';

import { useAnimationUrls } from '@components/Animation';
import { localizedString } from '@languages';
import { useFlowTypeContext } from '@js/lib/components/v2/App/FlowTypeProvider';
import { SimplePageContent } from '@lib/components/v2/SimplePageContent';
import APIs from '@services/APIs';
import { Error500 } from '@lib/components/v2/errors';
import { CapturePage } from '@FLOW_V2_FLOW/containers/Capture/CaptureBase';

export const CaptureSideOfCard = ({ onBack, onNext }) => {
  const [hasError, setHasError] = useState(false);
  const { flowType } = useFlowTypeContext();
  const { animationUrls } = useAnimationUrls();

  const uploadImage = (imagefile) => {
    const params = { frontFile: imagefile, flowType, ocr: false, idType: 'Additional Documents' };

    return APIs.uploadImage(params, {})
      .then(({ status, msg: error }) => {
        if (status === 'error') {
          console.warn(error);
          throw error;
        }
        onNext();
      })
      .catch((error) => {
        setHasError(true);
        throw error;
      });
  };

  if (hasError) {
    return (
      <Error500
        buttons={[
          {
            label: localizedString('pleaseTryAgain'),
            type: 'submit',
            onClick: () => setHasError(false),
            dataTestId: 'try-again-btn'
          }
        ]}
      />
    );
  }

  return (
    <CapturePage onBack={onBack} uploadImage={uploadImage}>
      <SimplePageContent
        title={parse(localizedString('app.captureSideCard.title'))}
        description={parse(localizedString('app.captureSideCard.description'))}
        animationUrl={animationUrls.SIDE_OF_CARD}
      />
    </CapturePage>
  );
};

CaptureSideOfCard.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};
