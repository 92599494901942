import React from 'react';
import PropTypes from 'prop-types';

import APIs from '@services/APIs';
import QuestionnaireForm from '@lib/components/v2/QuestionnaireForm';

const Questionnaire = ({ onGoBack, onComplete, enableExitButton, questionnaire }) => {
  const handleExit = () => {
    onGoBack();
  };

  const handleSubmit = (formData) => {
    APIs.storeQuestionnaireAnswers(formData);
    onComplete();
  };

  const {
    schemas,
    uiSchemas,
    schemaValidationErrors = {},
    theme = '',
    enableQuestionnaireReview = false
  } = questionnaire;

  return (
    <div>
      <QuestionnaireForm
        enableExitButton={enableExitButton}
        schemaValidationErrors={schemaValidationErrors}
        theme={theme}
        schemas={schemas}
        uiSchemas={uiSchemas}
        enableQuestionnaireReview={enableQuestionnaireReview}
        onExit={handleExit}
        onSubmit={handleSubmit}
      />
    </div>
  );
};

Questionnaire.propTypes = {
  onGoBack: PropTypes.func,
  onComplete: PropTypes.func,
  questionnaire: PropTypes.object,
  enableExitButton: PropTypes.bool
};

export default Questionnaire;
