import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Title } from '@lib/components/v2/Title';
import validator from '@rjsf/validator-ajv8';

import { Button } from '@lib/components/v2/Form';
import Page from '@lib/components/v2/Page';
import FormBootstrap3 from '@rjsf/core';
import { Form as FormBootstrap4 } from '@rjsf/bootstrap-4';
import parse from 'html-react-parser';

import { localizedString } from '@languages';
import CustomCheckboxes from './CustomCheckboxes';
import classes from './QuestionnaireForm.style.module.scss';
import { CustomMultipleSelectWidget } from './CustomMultipleSelectWidget';

const QuestionnaireForm = ({
  schemas,
  uiSchemas,
  onSubmit,
  onExit,
  enableQuestionnaireReview,
  schemaValidationErrors = {},
  enableExitButton,
  theme
}) => {
  const schemaKeys = Object.keys(schemas);
  const uiSchemaKeys = Object.keys(uiSchemas);

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [stepSchema, setStepSchema] = useState(schemas[schemaKeys[0]]);
  const [stepUiSchema, setStepUischema] = useState(uiSchemas[uiSchemaKeys[0]]);
  const [showReview, setShowReview] = useState(false);

  useEffect(() => {
    setStepSchema(schemas[schemaKeys[step]]);
    setStepUischema(uiSchemas[uiSchemaKeys[step]]);
  }, [step]);

  const isFirstStep = () => step === 0;

  const isLastStep = () => step === schemaKeys.length - 1;

  const handleSubmit = () => onSubmit(formData);

  const handleNextStep = () => setStep(step + 1);

  const handleLastStep = () => {
    if (enableQuestionnaireReview) {
      setShowReview(true);
    } else {
      handleSubmit();
    }
  };

  const handlePreviousStep = () => {
    if (isFirstStep()) {
      onExit();
      return;
    }

    setStep(step - 1);
  };

  const handleChange = ({ formData }) => setFormData(formData);

  const handleReedit = (formKey) => {
    setShowReview(false);
    setStepSchema(schemas[formKey]);
    setStepUischema(uiSchemas[formKey]);
  };

  function showReviewContent() {
    return Object.keys(uiSchemas).map((formKey) => {
      const formReview = renderFieldItem(formData, uiSchemas[formKey], formKey);

      return (
        <div className={classNames(classes.questionsGroup)} key={formKey}>
          <div className={classNames(classes.questions)}>{formReview}</div>
          <div className={classNames(classes.edit)}>
            <u role="presentation" onClick={() => handleReedit(formKey)}>
              Edit
            </u>
          </div>
        </div>
      );
    });
  }

  function renderFieldItem(parentFormData, parentUiSchema, classKey) {
    return Object.keys(parentUiSchema).map((key) => {
      if (key === '*') {
        // when key is *, loop through form data with same ui schema
        return parentFormData.map((itemData, index) =>
          renderFieldItem(itemData, parentUiSchema[key], classKey + index)
        );
      }

      if (!parentFormData[key]) {
        return null;
      }

      const childClassKey = classKey + key;

      if (parentUiSchema[key]['ui:title']) {
        return (
          <div key={childClassKey} className={classNames(classes.question)}>
            <div className={classNames(classes.title)}>{parentUiSchema[key]['ui:title']}</div>
            <div className={classNames(classes.answer)}>
              {Array.isArray(parentFormData[key])
                ? parentFormData[key].join(', ')
                : parentFormData[key]}
            </div>
          </div>
        );
      }

      return renderFieldItem(parentFormData[key], parentUiSchema[key], childClassKey);
    });
  }

  const renderImportantInfo = () => (
    <div className={classNames(classes.importantInfo)}>
      <h4>{localizedString('app.questionnaire.importantInfo.heading')}</h4>
      <p>{parse(localizedString('app.questionnaire.importantInfo.description'))}</p>
    </div>
  );

  const widgets = {
    CheckboxesWidget: CustomCheckboxes,
    MultipleSelectWidget: CustomMultipleSelectWidget
  };

  const transformErrors = (errorList) => {
    return errorList.map((error) => {
      const errorPropertyName = error.property
        .split('.')
        .map((field) => {
          if (field.length > 0 && !Number.isNaN(Number(field))) {
            return '*';
          }
          return field;
        })
        .join('.');

      if (
        schemaValidationErrors[errorPropertyName] &&
        schemaValidationErrors[errorPropertyName][error.name]
      ) {
        return {
          ...error,
          message: schemaValidationErrors[errorPropertyName][error.name]
        };
      }
      return error;
    });
  };

  const FormComponent = theme === 'bootstrap-3' ? FormBootstrap3 : FormBootstrap4;

  return (
    <Page isMessage>
      {!showReview && (
        <FormComponent
          showErrorList={false}
          widgets={widgets}
          validator={validator}
          transformErrors={transformErrors}
          schema={stepSchema}
          uiSchema={stepUiSchema}
          formData={formData}
          onChange={handleChange}
          onSubmit={() => {
            if (isLastStep()) {
              handleLastStep();
            } else {
              handleNextStep();
            }
          }}
          noHtml5Validate
        >
          <div className={classes.footer}>
            <div className={classes.buttonsWrapper}>
              <div className={classes.buttons}>
                <Button
                  type="button"
                  onClick={handlePreviousStep}
                  label={localizedString('back')}
                  variant="transparent"
                  disabled={!enableExitButton && isFirstStep()}
                />
                {!isLastStep() && <Button type="submit" label="Next" />}
                {isLastStep() && (
                  <Button type="submit" label={enableQuestionnaireReview ? 'Preview' : 'Submit'} />
                )}
              </div>
            </div>
          </div>
        </FormComponent>
      )}
      {showReview && (
        <div className={classNames(classes.review)}>
          <div className={classNames(classes.header)}>
            <Title title={localizedString('reviewAndSubmit')} />
          </div>
          <div className={classNames(classes.questionsGroups)}>
            {showReviewContent()}
            {renderImportantInfo()}
          </div>
          <div className={classes.footer}>
            <div className={classes.buttonsWrapper}>
              <div className={classes.buttons}>
                <Button
                  type="button"
                  label={localizedString('back')}
                  variant="transparent"
                  onClick={() => setShowReview(false)}
                />
                <Button type="button" label="Submit" onClick={handleSubmit} />
              </div>
            </div>
          </div>
        </div>
      )}
    </Page>
  );
};

QuestionnaireForm.propTypes = {
  schemas: PropTypes.object,
  uiSchemas: PropTypes.object,
  onSubmit: PropTypes.func,
  onExit: PropTypes.func,
  enableQuestionnaireReview: PropTypes.bool,
  schemaValidationErrors: PropTypes.object,
  theme: PropTypes.string,
  enableExitButton: PropTypes.bool
};

export default QuestionnaireForm;
