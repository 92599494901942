import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { localizedString } from '@languages';
import Page from '@lib/components/v2/Page';
import { isValidImageFile } from '@js/lib/validations/file';
import { DocNotAccepted } from '@lib/components/v2/VerifyDetails/VerifyDetails.errors';

export const CaptureBase = ({ uploadImage, children }) => {
  const [isUploading, setIsUploading] = useState(false);
  const inputRef = useRef();
  const [error, setError] = useState(null);

  const upload = async (e) => {
    const imageFile = e?.target?.files?.[0];
    if (!isValidImageFile(imageFile)) {
      setError({
        component: DocNotAccepted,
        props: {
          buttons: [
            {
              label: localizedString('tryAgain'),
              onClick: () => {
                setError(null);
              }
            }
          ]
        }
      });
      return;
    }

    setIsUploading(true);
    try {
      await uploadImage(imageFile);
    } catch (e) {
      console.error(e);
    }
    setIsUploading(false);
  };

  const { component: Error, props: errorProps } = error || {};
  if (Error) {
    return <Error {...errorProps} />;
  }

  const childrenProps = { isUploading, capture: () => inputRef.current?.click() };
  return (
    <>
      <input
        type="file"
        name="image"
        accept="image/*"
        capture="environment"
        onChange={upload}
        ref={inputRef}
        style={{ opacity: 0, zIindex: 99 }}
        data-testid="capture-base-upload-doc-hidden-input"
        aria-hidden="true"
      />
      {children(childrenProps)}
    </>
  );
};

CaptureBase.propTypes = {
  uploadImage: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired
};

export const CapturePage = ({ onBack, children, ...props }) => {
  return (
    <CaptureBase {...props}>
      {(childrenProps) => {
        const { isUploading, capture } = childrenProps;
        const footerButtons = isUploading
          ? [
              {
                label: '',
                variant: 'transparent',
                dataTestId: 'cptrId-back',
                'aria-hidden': true
              },
              {
                label: localizedString('uploading'),
                variant: 'transparent',
                loading: true,
                dataTestId: 'id-Uploading'
              }
            ]
          : [
              {
                label: localizedString('back'),
                variant: 'transparent',
                onClick: () => onBack(),
                dataTestId: 'cptrId-back'
              },
              {
                label: localizedString('captureMyID'),
                type: 'submit',
                onClick: () => capture(),
                dataTestId: 'cptrId-btn'
              }
            ];

        return (
          <Page buttons={footerButtons} forceFillViewPort>
            {children}
          </Page>
        );
      }}
    </CaptureBase>
  );
};

CapturePage.propTypes = {
  onBack: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired
};
