import { useRef, useState } from 'react';

export const STEPS = {
  FRONT: 'front',
  SIDE: 'side',
  BACK: 'back',
  VISA: 'visa'
};

export const useStep = ({ captureVisa }) => {
  const [currentStep, setCurrentStep] = useState(STEPS.FRONT);

  const steps = useRef([
    { name: STEPS.FRONT, enabled: true },
    { name: STEPS.SIDE, enabled: false },
    { name: STEPS.BACK, enabled: false },
    { name: STEPS.VISA, enabled: captureVisa || false }
  ]);

  const updateStep = (step, enabled) => {
    const index = steps.current.findIndex((s) => s.name === step);
    if (index >= 0) steps.current[index].enabled = enabled;
  };

  const getNextStep = () => {
    const currentIndex = steps.current.findIndex((step) => step.name === currentStep);
    return steps.current.find((step, i) => i > currentIndex && step.enabled);
  };

  const goToNextStep = () => {
    const nextStep = getNextStep();
    if (nextStep) setCurrentStep(nextStep.name);
  };

  const goToStep = (target) => {
    const targetStep = steps.current.find((step) => step.name === target);
    if (targetStep) setCurrentStep(target);
  };

  return { steps: steps.current, currentStep, getNextStep, goToNextStep, goToStep, updateStep };
};
