/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InfoAction from '@store/actions/information';
import CaptureAction from '@store/actions/capture';
import VoiFlowV2Action from '@store/actions/voiFlowV2';
import EditedAction from '@store/actions/edited';
import { DigitalDLTimeLimitError } from '@FLOW_V2_FLOW/components/ErrorScreens/DigitalDLTimeLimitError';
import { hasAdressBeingEdited } from '@lib/utils/editedInfo';
import { localizedString } from '@languages';
import APIs from '@services/APIs';

import { selectEligibleAge } from '@js/domain/ageEligibility';
import { isTimeLimitOKForDigitalScreenShot } from '@lib/utils/digitalDLCheck';
import { isProofOfAgeDocument } from '@lib/constants/documents';

import {
  ENABLE_ONE_DOC_CONDITION,
  VOI_FLOW_V2_HIDE_CHOOSE_DIFERENT_ID_SCREEN,
  VOI_FLOW_V2_HIDE_NAME_MATCH_SCREEN
} from '@spotMobileConfig';
import { CaptureTips } from '@lib/components/v2/Contents';
import { Error500 } from '@lib/components/v2/errors';
import {
  ConfirmGoBack,
  CouldNotRecognise,
  PoorQuality,
  Recapture,
  VoiV2DocNotAccepted
} from '@lib/components/v2/VerifyDetails/VerifyDetails.errors';
import { formatDay } from '@lib/utils/date';
import { isMedicare } from '@lib/utils/v2/document';
import { VerifyDetailsForm } from '@VOI_FLOW_V2/containers/VerifyDetails/VerifyDetailsForm';
import { MedicareNames } from '../../components/Contents';

const VerifyDetails = ({
  onNextStep,
  onGoBack,
  idType,
  location,
  verify,
  flowType,
  selectedDocumentList,
  prevScreenState,
  setEditedFields,
  onRecapture,
  onExtracted,
  cardDetails,
  appConfig,
  setFrontIDParams,
  currentDocument,
  clearCardDetails,
  setCardDetails,
  onCaptureBack,
  onChooseDiffId,
  eligibleAge,
  setConfirmedInfo,
  editedFields,
  lastConfirmedDetails
}) => {
  const [state, set] = useState(getInitialState());
  const setState = (objectOrFunction) => {
    if (typeof objectOrFunction === 'function') {
      return set(objectOrFunction);
    }

    return set((oldState) => ({ ...oldState, ...objectOrFunction }));
  };

  function getInitialState() {
    return {
      data: lastConfirmedDetails
        ? { ...lastConfirmedDetails }
        : {
            checkConfirm: false,
            dateOfBirth: '',
            expiryDate: '',
            dataCheckConsentAt: ''
          },
      extracted: {},
      pageTitle: '',

      isUploading: true,
      progressBar: 0,

      error: null,
      loading: false,
      isLoading: false,
      isWaiting: false,
      currentDoc: {},
      token: '',
      showMedicareNames: false,
      medicareNames: {},
      showCaptureTips: '',
      useIdNumber: false
    };
  }

  useEffect(() => {
    if (prevScreenState) {
      setState(prevScreenState);
      return;
    }

    const unextractedDocumentList = selectedDocumentList.filter((doc) => {
      return !doc.extracted;
    });

    if (unextractedDocumentList.length > 0) {
      const currentDoc = unextractedDocumentList[0];
      currentDoc.docIndex = selectedDocumentList.findIndex(
        (doc2) => doc2.type === unextractedDocumentList[0].type
      );

      setState({ currentDoc });
      uploadImage(unextractedDocumentList[0]);
    } else {
      console.error('All the documents are processed');
    }
  }, []);

  const handleRecapture = (card) => {
    setEditedFields([]);
    onRecapture(card);
  };

  const onSubmit = async ({ dirty, fields, validateAddress, changes }) => {
    let isOtherCard = cardDetails.length > 0;
    const { currentDoc, data } = state;

    const { addressData } = data;

    setState({ loading: true });

    if (hasAdressBeingEdited({ dirty, fields })) {
      changes.push('addressData');
    }
    const finalData = {
      ...data,
      location,
      flowType,
      edited: [...changes],
      countryCode: data.countryCode,
      validateAddress
    };
    if (addressData) {
      finalData.addressData = addressData;
    } else if (!addressData && flowType === 'VOI_FLOW_V2') {
      finalData.addressData = { fullAddress: 'NA' };
    }

    if (!finalData.cardType) {
      finalData.cardType = currentDoc.type;
    }

    let isOtherCardForQCondition = false;
    if (ENABLE_ONE_DOC_CONDITION) {
      const { index } = currentDocument;

      if (index <= 0) {
        await clearCardDetails();
      } else if (index === 1) {
        isOtherCardForQCondition = true;
      }
    }

    setConfirmedInfo({ ...data });

    setEditedFields([]);

    const pendingIDs = selectedDocumentList.filter((doc) => {
      return !doc.extracted && doc.type !== currentDoc.type;
    });

    isOtherCard = cardDetails.length > 0 || isOtherCardForQCondition;
    APIs.store(finalData, isOtherCard)
      .then(({ status, type, msg, nameMatched }) => {
        setState({ loading: false });
        if (status !== 'success') {
          setState({
            error: {
              component: Error500,
              props: {
                issue: msg,
                buttons: [
                  {
                    label: localizedString('cancel'),
                    onClick: () => {
                      if (type === 'cards') {
                        onGoBack();
                      } else {
                        setState({
                          error: null
                        });
                      }
                    }
                  }
                ]
              }
            }
          });
          return;
        }

        setCardDetails(finalData);

        if (pendingIDs.length === 0) {
          let nextStep = { step: 2 };
          if (isOtherCard && !nameMatched && !VOI_FLOW_V2_HIDE_NAME_MATCH_SCREEN) {
            nextStep = { showNameMatch: true, prevScreenState: state };
          } else if (!VOI_FLOW_V2_HIDE_CHOOSE_DIFERENT_ID_SCREEN && !ENABLE_ONE_DOC_CONDITION) {
            // It's OCR for Name Certs
            if (currentDoc.nextAfterOcr) {
              nextStep = currentDoc.nextAfterOcr;
            }
          } else {
            nextStep.secondDocument = determineSecondDocument(data);
          }

          onNextStep(nextStep);
        } else {
          const state = getInitialState();

          const currentDoc = pendingIDs[0];
          currentDoc.docIndex = selectedDocumentList.findIndex((doc2) => {
            return doc2.type === pendingIDs[0].type;
          });
          setState({ ...state, currentDoc });
          uploadImage(pendingIDs[0]);
        }
      })
      .catch(({ message }) => {
        console.error(message);
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              handleRecapture(currentDoc);
            }
          }
        };
        setState({ error });
      });
  };

  const handleGoBack = () => {
    const { currentDoc } = state;
    const error = {
      component: ConfirmGoBack,
      props: {
        buttons: [
          {
            label: localizedString('cancel'),
            variant: 'outline',
            onClick: () => {
              setState({ error: null });
            }
          },
          {
            label: localizedString('yes'),
            onClick: () => {
              setState({ error: null });
              handleRecapture(currentDoc);
            }
          }
        ]
      }
    };
    setState({ error });
  };

  const onMedicareNameSelected = (newData) => {
    const { data } = state;
    setState({
      data: { ...data, ...newData },
      showMedicareNames: false
    });
  };

  function onCaptureAgain() {
    const { currentDoc } = state;
    setState({ showCaptureTips: '' });
    handleRecapture(currentDoc);
  }

  const checkNameDiff = () => {
    const { currentDoc } = state;
    const updatedDocumentList = onExtracted(currentDoc);
    const pendingIDs = updatedDocumentList.filter((doc) => {
      return !doc.extracted && doc.ocr;
    });

    if (pendingIDs.length === 0) {
      APIs.checkNameDiff()
        .then(({ nameMatched }) => {
          let nextStep = { step: 2 };
          if (!nameMatched) {
            nextStep = { showNameMatch: true, prevScreenState: state };
          }

          // It's OCR for Name Certs
          if (currentDoc.nextAfterOcr) {
            nextStep = currentDoc.nextAfterOcr;
          }

          onNextStep(nextStep);
        })
        .catch(({ message }) => {
          console.error(message);
          const error = {
            component: Error500,
            props: {
              onTryAgain: () => {
                handleRecapture(currentDoc);
              }
            }
          };
          setState({ error });
        });
    } else {
      const state = getInitialState();
      setState({ ...state, currentDoc: pendingIDs[0] });
      uploadImage(pendingIDs[0]);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  const incrementRecaptureCount = (selectedDoc) => {
    if (!sessionStorage.getItem(`voi_v2_recapture_count_${selectedDoc.type}`)) {
      sessionStorage.setItem(`voi_v2_recapture_count_${selectedDoc.type}`, 1);
    } else {
      let count = sessionStorage.getItem(`voi_v2_recapture_count_${selectedDoc.type}`);
      count = parseInt(count, 10);
      sessionStorage.setItem(`voi_v2_recapture_count_${selectedDoc.type}`, count + 1);
    }
  };

  const loadData = (selectedDoc, token) => {
    const { type: idType, title } = selectedDoc;
    const hasNoBackCard = !selectedDoc.backOfCard;

    setState({
      isLoading: true,
      pageTitle: localizedString('verifyDetails.voiv2.title', title),
      currentDoc: selectedDoc
    });

    APIs.extractIdentifyInfo(token, hasNoBackCard, {
      isEngineV4: true,
      idType,
      documentId: selectedDoc.documentId
    })
      .then(async (res = {}) => {
        const {
          data: cardData,
          code,
          poorQuality = null,
          skip = false,
          docAccepted = true,
          captureBackCard = false,
          useIdNumber = false
        } = res;
        setState({ useIdNumber });

        if (!VOI_FLOW_V2_HIDE_NAME_MATCH_SCREEN && skip) {
          // skip to next step
          checkNameDiff();
          return;
        }

        const buttons = [
          {
            label: localizedString('back'),
            variant: 'transparent',
            onClick: () => {
              setState({ error: null });
              onGoBack(selectedDoc);
            },
            dataTestId: 'capture-backBtn'
          }
        ];

        if (code !== '200') {
          let count = sessionStorage.getItem(`voi_v2_recapture_count_${selectedDoc.type}`);
          count = parseInt(count, 10);
          if (count >= 1 && !VOI_FLOW_V2_HIDE_CHOOSE_DIFERENT_ID_SCREEN) {
            buttons.push({
              label: 'Choose another ID',
              onClick: () => {
                setState({ error: null, showCaptureTips: 'CouldntRecognise' });
                handleChooseDiffId();
                setEditedFields([]);
              }
            });
            const error = {
              // component: FaceNotDetectedInId,
              component: CouldNotRecognise,
              props: { buttons, idName: title }
            };

            setState({
              error,
              isLoading: false,
              progressBar: 0
            });

            return;
          }
        }

        if (docAccepted === false) {
          incrementRecaptureCount(selectedDoc);
          buttons.push({
            label: localizedString('RecaptureID'),
            onClick: () => {
              setState({ error: null });
              handleRecapture(selectedDoc);
            }
          });
          const error = {
            component: VoiV2DocNotAccepted,
            props: { buttons }
          };
          setState({
            error,
            isLoading: false,
            progressBar: 0
          });
          return;
        }

        if (code === '220' || code === '230' || code === '240' || code === '270') {
          incrementRecaptureCount(selectedDoc);
          buttons.push({
            label: localizedString('capture.FLOW_V2_VIEW_TIPS_BUTTON'),
            onClick: () => {
              setFrontIDParams({});
              setState({ error: null, showCaptureTips: 'CouldntRecognise' });
            }
          });
          const error = {
            component: CouldNotRecognise,
            props: { buttons, idName: title }
          };
          setState({
            error,
            isLoading: false,
            progressBar: 0
          });
          return;
        }

        if (code !== '200') {
          incrementRecaptureCount(selectedDoc);
          buttons.push({
            label: localizedString('capture.FLOW_V2_VIEW_TIPS_BUTTON'),
            onClick: () => {
              setFrontIDParams({});
              setState({ error: null, showCaptureTips: 'CouldntRecognise' });
            }
          });
          const error = {
            component: CouldNotRecognise,
            props: { buttons, idName: title }
          };
          setState({
            error,
            isLoading: false,
            progressBar: 0
          });
          return;
        }

        if (poorQuality) {
          incrementRecaptureCount(selectedDoc);
          buttons.push({
            label: localizedString('capture.FLOW_V2_VIEW_TIPS_BUTTON'),
            onClick: () => {
              setFrontIDParams({});
              setState({ error: null, showCaptureTips: 'PoorQuality' });
            },
            dataTestId: 'betterImg-viewTipsBtn'
          });
          const error = {
            component: PoorQuality,
            props: { buttons, cropped: poorQuality, idName: title }
          };
          setState({
            error,
            isLoading: false,
            progressBar: 0
          });
          return;
        }

        if (!isTimeLimitOKForDigitalScreenShot(cardData)) {
          incrementRecaptureCount(selectedDoc);
          buttons.push({
            label: localizedString('tryAgain'),
            onClick: () => {
              setState({ error: null });
              onCaptureAgain();
            }
          });

          const error = {
            component: DigitalDLTimeLimitError,
            props: { buttons }
          };
          setState({
            error,
            isLoading: false,
            progressBar: 0
          });
          return;
        }

        const country = cardData.countryCode;

        if (!selectedDoc.params.backFile && captureBackCard) {
          if (cardData.cardType.match(/LICENCE/i) && country === 'UK') {
            // Skip
          } else {
            onCaptureBack(selectedDoc);
            return;
          }
        }

        sessionStorage.removeItem(`voi_v2_recapture_count_${selectedDoc.type}`);

        const temp = cardData;
        // validate dates
        // temp['dateOfBirth'] = validateDate(temp['dateOfBirth']) ? temp['dateOfBirth'] : ''
        // temp['expiryDate'] = validateDate(temp['expiryDate']) ? temp['expiryDate'] : ''

        // format dates
        if (temp.dateOfBirth) temp.dateOfBirth = formatDay(temp.dateOfBirth);
        if (temp.expiryDate) temp.expiryDate = formatDay(temp.expiryDate);

        temp.countryOfIssue = cardData.country;

        let nameCount = 0;
        if (cardData.names && isMedicare(cardData.cardType)) {
          nameCount = Object.keys(cardData.names).length;
        }

        if (nameCount > 0) {
          if (nameCount > 1) {
            setState({
              showMedicareNames: true,
              medicareNames: cardData.names
            });
          } else {
            // Only one name
            const nameKey = Object.keys(cardData.names)[0];
            const nameValue = cardData.names[nameKey];
            const nameString = `${nameKey} ${nameValue}`;

            const parts = nameString.split(/\s(.+)/);

            [temp.medicareRefNumber, temp.fullName] = parts;
          }

          delete temp.names;
        }

        setState({ data: temp, extracted: res.data });

        if (!verify) {
          setState({
            isLoading: false
          });

          APIs.status(
            selectedDocumentList[0].type === selectedDoc.type ? 'reviewInfo' : 'reviewOtherInfo'
          );
        } else {
          setState({
            isLoading: false,
            isWaiting: true
          });

          await APIs.status('preparingCards').then(() => {
            return APIs.checkApproval().then((status) => {
              setState({ isWaiting: false });
              if (status !== 'approved') {
                const error = {
                  component: Recapture,
                  props: {
                    buttons: [
                      {
                        label: localizedString('recapture'),
                        onClick: () => {
                          setFrontIDParams({});
                        }
                      }
                    ]
                  }
                };
                setState({ error, isLoading: false });
              }
            });
          });
        }
      })
      .catch((e) => {
        console.error(e);
        const error = {
          component: Error500
        };
        setState({ error });
      });
  };

  function handleChooseDiffId() {
    if (!VOI_FLOW_V2_HIDE_CHOOSE_DIFERENT_ID_SCREEN) {
      const { currentDoc } = state;
      onChooseDiffId(currentDoc);
    }
  }

  function uploadImage(selected) {
    const selectedIndex = selectedDocumentList.findIndex((card) => {
      return card.type === selected.type;
    });

    APIs.status(selectedIndex === 0 ? 'capturingId' : 'capturingOtherId', {
      resetTxnData: selectedIndex === 0
    });

    const { title, params } = selected;
    setState({
      isUploading: true,
      progressBar: 0,
      isLoading: true,
      pageTitle: localizedString('verifyDetails.voiv2.title', title)
    });

    APIs.uploadImage(
      { ...params, idType: selected.type },
      {
        before: () => setState({ progressBar: 0 }),
        onProgress: (width) => setState({ progressBar: width })
      }
    )
      .then(async ({ status, token, msg }) => {
        if (status === 'error') {
          console.error('error', msg);

          const error = {
            component: Error500,
            props: {
              onTryAgain: () => {
                setState({ error: null });
                handleRecapture(selected);
              }
            }
          };

          setState({
            error,
            isUploading: false,
            progressBar: 0
          });

          return;
        }

        setState({ isUploading: false, token });

        if (selected.ocr) {
          loadData(selected, token);
        } else {
          const updatedDocumentList = onExtracted(selected);
          const pendingIDs = updatedDocumentList.filter((doc) => {
            return !doc.extracted;
          });

          if (pendingIDs.length === 0) {
            // OCR is False
            let nextStep = { step: 2 };

            if (selected.nextAfterOcr) {
              nextStep = selected.nextAfterOcr;
            }
            onNextStep(nextStep);
          } else {
            const state = getInitialState();
            if (
              ['AUS_AUTO_BIRTH', 'AUS_AUTO_NAME', 'AUS_AUTO_MARRIAGE'].includes(pendingIDs[0].type)
            ) {
              state.showStateSelection = true;
              setState({ ...state, currentDoc: pendingIDs[0] });
            } else {
              setState({ ...state, currentDoc: pendingIDs[0] });
              uploadImage(pendingIDs[0]);
            }
          }
        }
      })
      .catch((e) => {
        console.error(e);
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              setState({ error: null });
              handleRecapture(selected);
            }
          }
        };

        setState({ error });
      });
  }

  function determineSecondDocument(data) {
    let result = false;

    if (currentDocument.index < 1) {
      const { conditions, state, cardType } = data;

      if (
        conditions &&
        conditions.includes('Q') &&
        state === 'NSW' &&
        cardType === 'Driver Licence'
      ) {
        result = true;
      } else if (cardType === 'Driver Licence' && state !== 'NSW') {
        result = true;
      } else if (isProofOfAgeDocument(cardType)) {
        // This is Proof Of Age card
        result = true;
      }
    }

    return result;
  }

  const { error, showMedicareNames, medicareNames, showCaptureTips = '' } = state;

  const { component: Error, props: errorProps } = error || {};

  return (
    <div>
      {Error && <Error {...errorProps} />}
      {showCaptureTips !== '' && (
        <CaptureTips
          type={showCaptureTips}
          onHide={() => onCaptureAgain()}
          onCaptureAgain={() => onCaptureAgain()}
          resetTables="no"
        />
      )}
      {showMedicareNames && showCaptureTips === '' && (
        <MedicareNames
          onSelected={onMedicareNameSelected}
          onGoBack={handleGoBack}
          names={medicareNames}
        />
      )}
      {!showMedicareNames && showCaptureTips === '' && (
        <VerifyDetailsForm
          onSubmit={onSubmit}
          onGoBack={onGoBack}
          setEditedFields={setEditedFields}
          setFrontIDParams={setFrontIDParams}
          handleRecapture={handleRecapture}
          handleGoBack={handleGoBack}
          onExtracted={onExtracted}
          state={state}
          setState={setState}
          eligibleAge={eligibleAge}
          idType={idType}
          flowType={flowType}
          appConfig={appConfig}
          editedFields={editedFields}
          currentDocument={currentDocument}
          selectedDocumentList={selectedDocumentList}
        />
      )}
    </div>
  );
};
VerifyDetails.propTypes = {
  onNextStep: PropTypes.func,
  onGoBack: PropTypes.func,
  idType: PropTypes.string,
  token: PropTypes.string,
  location: PropTypes.string,
  verify: PropTypes.bool,
  flowType: PropTypes.string,
  selectedDocumentList: PropTypes.array,
  prevScreenState: PropTypes.any,
  setEditedFields: PropTypes.func,
  onRecapture: PropTypes.func,
  onExtracted: PropTypes.func,
  cardDetails: PropTypes.array,
  appConfig: PropTypes.object,
  setFrontIDParams: PropTypes.func,
  currentDocument: PropTypes.any,
  clearCardDetails: PropTypes.func,
  setCardDetails: PropTypes.func,
  onCaptureBack: PropTypes.func,
  onChooseDiffId: PropTypes.func,
  eligibleAge: PropTypes.number,
  setConfirmedInfo: PropTypes.func,
  editedFields: PropTypes.any,
  lastConfirmedDetails: PropTypes.any
};

VerifyDetails.defaultProps = {
  onNextStep: () => null,
  onGoBack: () => null,
  idType: 'NZL_DRIVERLICENCE',
  token: '',
  verify: false
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyDetails);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps(state) {
  const { capture, appConfig, voiFlowV2, edited, information } = state;
  return {
    frontParams: capture.frontParams,
    appConfig,
    cardDetails: voiFlowV2.cardDetails,
    editedFields: edited.fields,
    eligibleAge: selectEligibleAge(state),
    lastConfirmedDetails: information.lastConfirmedDetails
  };
}

/**
 * Map the dispatch function of the store to the component's props
 * @param  {Function} dispatch The dispatch function
 * @return {Object}
 */
function mapDispatchToProps(dispatch) {
  return {
    clearCardDetails: () => dispatch(VoiFlowV2Action.clearCardDetails()),
    setCardDetails: (data) => dispatch(VoiFlowV2Action.setCardDetails(data)),
    setFrontIDParams: (data) => dispatch(CaptureAction.setFrontIDParams(data)),
    setEditedFields: (data) => dispatch(EditedAction.setEditedFields(data)),
    setConfirmedInfo: (data) => dispatch(InfoAction.setConfirmedInfo(data))
  };
}
