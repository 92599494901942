import { ALLOW_MOBILE_EMULATORS } from '@spotMobileConfig';

import { isMobile as isMobileFromReactDeviceDetect, isIOS, isAndroid } from 'react-device-detect';

const checkMobileDetection = () => {
  if (!isMobileFromReactDeviceDetect) {
    return false;
  }

  if (!isMobileFromMaxTouchPoints()) {
    return false;
  }

  if (!isMobileFromPlatform()) {
    return false;
  }

  if (!isMobileFromUserAgentData()) {
    return false;
  }

  return true;
};

function isMobileFromPlatform() {
  // navigator.platform is still supported dn working as today. But be aware of being deprecated. It can change soon.

  if (isIOS) {
    return ['iPhone', 'iPad'].includes(navigator.platform);
  }

  if (isAndroid) {
    // Intentionally duplicates ending in 1 (number 1) or l (lowercase L)
    // There may be others but those are the more commons
    return [
      'Android',
      'Linux armv7l',
      'Linux armv71',
      'Linux armv8l',
      'Linux armv81',
      'Linux aarch64'
    ].includes(navigator.platform);
  }

  return false;
}

function isMobileFromUserAgentData() {
  // navigator.userAgentData not supported in all os and browsers

  if (typeof navigator.userAgentData !== 'undefined') {
    return navigator.userAgentData.mobile;
  }
  return true;
}

function isMobileFromMaxTouchPoints() {
  return 'ontouchstart' in window && navigator.maxTouchPoints > 1;
}

export default ALLOW_MOBILE_EMULATORS ? isMobileFromReactDeviceDetect : checkMobileDetection();
