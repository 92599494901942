import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@lib/components/v2/Form';
import ReactSwipe from 'react-swipe';
import { localizedString } from '@languages';
import classes from './Tips.style.module.scss';

const Tips = ({ idType, tips, display, lastButtonText, fullWidth, country, onCapture }) => {
  const [step, setStep] = useState(0);
  const reactSwipeRef = useRef(null);

  const handleClick = () => {
    if (step < tips.length - 1) {
      reactSwipeRef.current?.next();
    } else {
      onCapture();
    }
  };

  const getTipFolder = (idType) => {
    if (
      idType.includes('DRIVERLICENCE') ||
      idType.includes('DLCARD') ||
      idType === 'PROOF_OF_AGE'
    ) {
      return 'licence';
    }

    if (
      idType.includes('IDCARD') ||
      idType.includes('ID_CARD') ||
      idType.includes('IDENTITYCARD')
    ) {
      return 'idcard';
    }

    return 'passport';
  };

  const title = tips[step];
  const buttonText = step === tips.length - 1 ? lastButtonText : localizedString('next');
  const swipeOptions = {
    continuous: false,
    callback: (step) => {
      setStep(step);
    }
  };

  let path;
  if (country === 'OTHER' && idType === 'PASSPORT') {
    path = `images/tips/${idType !== 'PASSPORT' ? 'licence' : 'passport'}/NZ`;
  } else if (idType === 'VISA') {
    path = `images/tips/visa`;
  } else {
    path = `images/tips/${getTipFolder(idType)}/${country}`;
  }

  const imgs = tips.map((tip, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <img key={i} alt="" src={`${path}/tip-${i + 1}.jpg`} data-testid={`cptrId-animation-${i}`} />
  ));

  const Dots = () => {
    return tips.map((tip, i) => (
      // eslint-disable-next-line react/no-array-index-key
      <li key={i} className={i === step ? classes.active : ''} data-testid={`cptrId-dot-${i}`} />
    ));
  };

  return (
    <div className={classNames(classes.wrapper, display)}>
      <div className="wp-b-tipsHeader">
        <h3
          style={{ width: fullWidth && '100%' }}
          className="b-title"
          data-testid="cptrId-instruction"
        >
          {title}
        </h3>
      </div>
      <ReactSwipe className={classes.image} swipeOptions={swipeOptions} ref={reactSwipeRef}>
        {imgs}
      </ReactSwipe>
      <ul className={classes.dots}>
        <Dots />
      </ul>
      <Button onClick={handleClick} shadow large full label={buttonText} dataTestId="cptrId-next" />
    </div>
  );
};

Tips.propTypes = {
  idType: PropTypes.string,
  onCapture: PropTypes.func.isRequired,
  lastButtonText: PropTypes.string,
  fullWidth: PropTypes.bool,
  tips: PropTypes.array,
  country: PropTypes.string,
  display: PropTypes.string
};

Tips.defaultProps = {
  idType: 'licence',
  fullWidth: false,
  tips: [],
  country: 'NZ'
};

export default Tips;
