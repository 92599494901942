import { isEmpty, isArray, difference, concat } from 'lodash';

export const getDocumentList = ({
  alreadySelectedDocs = [],
  documentListPart1 = [],
  documentListPart2 = [],
  filterDocs = {},
  conditionsToBeConsideredAsComplete = []
} = {}) => {
  if (difference(documentListPart1, alreadySelectedDocs).length === documentListPart1.length) {
    return {
      documentTypesToShow: concat(documentListPart1, ...alreadySelectedDocs),
      isComplete: false
    };
  }

  const isComplete = conditionsToBeConsideredAsComplete.reduce((result, condition) => {
    const doSelectedDocumentsMatch = !isEmpty(condition.possibleDocs)
      ? difference(alreadySelectedDocs, condition.possibleDocs).length === 0
      : true;
    return (
      result ||
      (condition.numberOfDocsRequired === alreadySelectedDocs.length && doSelectedDocumentsMatch)
    );
  }, false);

  if (isComplete) {
    return { documentTypesToShow: alreadySelectedDocs, isComplete: true };
  }

  let combinedList = [...documentListPart1, ...documentListPart2];

  if (!isEmpty(filterDocs) && !isEmpty(filterDocs.appearOnlyAsThirdDoc)) {
    const nextDocPositionCountingFromOne = alreadySelectedDocs.length + 1;

    combinedList = combinedList.filter((item) => {
      if (filterDocs.appearOnlyAsThirdDoc.includes(item)) {
        return nextDocPositionCountingFromOne === 3;
      }
      return true;
    });
  }

  if (!isEmpty(filterDocs) && !isEmpty(filterDocs.filters)) {
    combinedList = combinedList.filter((item) => {
      const filters = filterDocs.filters.filter(({ ifSelected }) => {
        if (!isArray(ifSelected)) {
          return false;
        }
        return difference(ifSelected, alreadySelectedDocs).length === 0;
      });

      if (!isEmpty(filters)) {
        return !filters.find((filter) => {
          return filter.filterOut.includes(item);
        });
      }

      return true;
    });
  }

  return { documentTypesToShow: combinedList, isComplete: false };
};
