export const isLicence = (value = '') => /LICENCE/i.test(value);

export const isPassport = (value = '') => /PASSPORT/i.test(value);

export const isPassportCard = (value = '') => /PASSPORT CARD/i.test(value);

export const isMedicare = (value = '') => /MEDICARE/i.test(value);

export const isHealthInsuranceCard = (value = '') => /HEALTH INSURANCE/i.test(value);

export const isBirth = (value = '') => /BIRTH/i.test(value);

export const isOzBirth = (value = '') => /AUS_[A-Z]+_BIRTH/i.test(value);

export const isMarriage = (value = '') => /MARRIAGE/i.test(value);

export const isOzMarriage = (value = '') => /AUS_[A-Z]+_MARRIAGE/i.test(value);

export const isCitizen = (value = '') => /CITIZEN/i.test(value);

export const isImmicard = (value = '') => /IMMICARD/i.test(value) || /Immigration/i.test(value);

export const isVisa = (value = '') => /VISA/i.test(value);

export const isIdCard = (value = '') => /IDCARD/i.test(value);

export const isIdentityCard = (value = '') => /IDENTITYCARD/i.test(value);

export const isPhotoCard = (value = '') => /PHOTOCARD/i.test(value);

export const isProofOfAge = (value = '') => /PROOFOFAGE/i.test(value);

export const isProofOfAddress = (value = '') => /PROOF_OF_ADDRESS/i.test(value);

export const isResidencePermit = (value = '') => /Residence Permit/i.test(value);

export const isSocialSecurityCard = (value = '') => /Social Security Card/i.test(value);

export const isTaxId = (value = '') => /Tax ID/i.test(value);

export const isVotingCard = (value = '') => /Voting Card/i.test(value);

export const isOzName = (value = '') => /AUS_[A-Z]+_NAME/i.test(value);
