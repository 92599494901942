import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import APIs from '@services/APIs';
import { getCookie, setCookie } from '@lib/Utils';
import { localizedString } from '@languages';
import { Prepare } from '@lib/components/v2/Contents/FaceScan';
import { Error500 } from '@lib/components/v2/errors';
import { isValidImageFile } from '@lib/validations/file';
import { LoadingSpinner } from '@FLOW_V2_FLOW/components';

const AlternateFlow = ({ onNextStep, onGoBack }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const imageFile = e.target.files[0];
    if (!isValidImageFile(imageFile)) {
      setError({
        component: Error500,
        props: {
          onTryAgain: () => setError(null)
        }
      });
      return;
    }

    e.target.value = '';

    const params = { face: imageFile, isEngineV4: true };

    setIsUploading(true);
    APIs.uploadSelfieImage(params, {})
      .then(({ status, msg: error, redirectTo }) => {
        if (status !== 'success') {
          throw error;
        }

        onNextStep({ completed: true, redirectTo });
      })
      .catch(() => {
        let attempt = parseInt(getCookie('retryAttempt'), 10) || 0;
        attempt += 1;
        setCookie('retryAttempt', attempt.toString(), 1);
        setError({
          component: Error500,
          props: {
            onTryAgain: () => setError(null)
          }
        });
        setIsUploading(false);
      });
  };

  const handleGoBack = () => {
    onGoBack();
  };

  const { component: Error, props: errorProps } = error || {};
  return (
    <div>
      {Error && <Error {...errorProps} />}
      {isUploading && <LoadingSpinner heading={localizedString('uploading')} />}
      {!isUploading && (
        <div>
          <Prepare selfie onGoBack={handleGoBack} onStart={() => fileInputRef.current?.click()} />
        </div>
      )}
      <input
        type="file"
        name="image"
        accept="image/*"
        capture="user"
        onChange={handleChange}
        ref={fileInputRef}
        style={{ opacity: 0, zIndex: 99 }}
        data-testid="selfie-upload-hidden-input"
        aria-hidden
      />
    </div>
  );
};

AlternateFlow.propTypes = {
  onNextStep: PropTypes.func,
  onGoBack: PropTypes.func
};

export default AlternateFlow;
