import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import { localizedString } from '@languages';
import Message from '@lib/components/v2/Message';

export const Error500 = (props) => {
  const { onTryAgain, ...restProps } = props;
  const buttons = onTryAgain
    ? [
        {
          label: localizedString('tryAgain'),
          full: true,
          onClick: onTryAgain
        }
      ]
    : [];

  return (
    <Message
      title={localizedString('errors.FLOW_V2_ERROR_500_ALERT_TITLE')}
      buttons={buttons}
      {...restProps}
      issue
    >
      {parse(localizedString('errors.FLOW_V2_ERROR_500_ALERT_DESCRIPTION'))}
    </Message>
  );
};

Error500.propTypes = {
  onTryAgain: PropTypes.func
};

/**
 * Error 400
 */
export const Error400 = (props) => {
  const { onRetryAgain = () => null, ...restProps } = props;

  return (
    <Message
      {...restProps}
      buttons={[
        {
          label: localizedString('tryAgain'),
          full: true,
          onClick: onRetryAgain
        }
      ]}
      title={localizedString('errors.FLOW_V2_ERROR_400_ALERT_TITLE')}
      issue
    >
      {parse(localizedString('errors.FLOW_V2_ERROR_400_ALERT_DESCRIPTION'))}
    </Message>
  );
};

Error400.propTypes = {
  onRetryAgain: PropTypes.func
};

/**
 * Connectivity issue
 */
export const InternetCut = (props) => {
  const { onTryAgain } = props;
  return (
    <Message
      {...props}
      buttons={[
        {
          label: localizedString('errors.FLOW_V2_CONNECTION_ERROR_ALERT_BUTTON'),
          shadow: true,
          onClick: () => onTryAgain()
        }
      ]}
      title={localizedString('connectionError')}
      issue
    >
      {parse(localizedString('checkInternetConnection'))}
    </Message>
  );
};

InternetCut.propTypes = {
  onTryAgain: PropTypes.func.isRequired
};

/**
 * Device Exceptions
 * */
export const LockedTransactionUrl = (props) => {
  const { transactionUrlLockExpiry } = props;

  return (
    <Message
      {...props}
      title={localizedString('lockedTransactionUrl')}
      issue={localizedString('identityCaptureInProgress')}
    >
      <div>
        {localizedString('verifyYouDontHaveOtherOpenTabs')}
        <br />
        {localizedString('pleaseCloseOtherTabsAndTryAgainAtXMinutes', transactionUrlLockExpiry)}
      </div>
    </Message>
  );
};

LockedTransactionUrl.propTypes = {
  transactionUrlLockExpiry: PropTypes.number
};
