import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';
import Animation, { useAnimationUrls } from '@components/Animation';
import { localizedString } from '@languages';
import { useAllowedDocumentsInIDVC } from '@js/hooks/useAllowedDocumentsInIDVC';
import { FLOW_V2_ID_SELECTION_SHOW_ACCEPTABLE_DOCS } from '@spotMobileConfig';
import Button from '@lib/components/v2/Form/Button';
import { Title } from '@lib/components/v2/Title';
import classes from './IdSelection.style.module.scss';

const IdSelection = ({
  onShowAcceptableDocsClick,
  idType,
  onShowCaptureDigitalIdScreen = () => {}
}) => {
  const { animationUrls } = useAnimationUrls();

  const { isDigitalIdEnabled } = useAllowedDocumentsInIDVC({
    omitDDL: true
  });

  return (
    <div style={{ height: '100%' }}>
      <div className={classes.wrapper}>
        <Title
          data-testid="cptrId-heading"
          title={localizedString('idSelection.FLOW_V2_ID_SELECTION_HEADING')}
        />
        <div className={classes.description} data-testid="cptrId-instruction">
          {parse(localizedString('idSelection.FLOW_V2_ID_SELECTION_DESCRIPTION'))}
        </div>
        <div className={classes['animation-container']} data-testid="cptrId-animation">
          <Animation
            animationUrl={idType !== 'PASSPORT' ? animationUrls.CARD : animationUrls.PASSPORT}
          />
        </div>

        <div className={classes.link_list}>
          {FLOW_V2_ID_SELECTION_SHOW_ACCEPTABLE_DOCS && (
            <Button
              onClick={onShowAcceptableDocsClick}
              className={classes.link}
              variant="transparent"
            >
              <span>{localizedString('acceptableDocs')}</span>
              <img alt="" src="images/icons/png/next-videoid.png" />
            </Button>
          )}
          {isDigitalIdEnabled && (
            <Button
              onClick={onShowCaptureDigitalIdScreen}
              className={classes.link}
              variant="transparent"
            >
              <span>{localizedString('idSelection.FLOW_V2_DIGITAL_ID_ACCEPTANCE_TITLE')}</span>
              <img alt="arrow" src="images/icons/png/next-videoid.png" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

IdSelection.propTypes = {
  onShowAcceptableDocsClick: PropTypes.func,
  idType: PropTypes.string,
  onShowCaptureDigitalIdScreen: PropTypes.func
};
export default IdSelection;
