import { localizedString } from '@languages';
import {
  FLOW_V2_VERIFY_DETAILS_SHOW_CHECKBOX_IF_NO_NAME,
  FLOW_V2_VERIFY_DETAILS_SHOW_ERROR_FOR_1_CHAR_MIDDLE_NAME
} from '@spotMobileConfig';

import {
  defaultLettersAndSymbolsRegexMatch,
  getFieldsValidation
} from '@lib/validations/vefifyDetailsFormValidations';

export function getValidations({ idType, countryOfIssue = '', values = {}, flowType, cardType }) {
  const validations = getFieldsValidation({ idType, countryOfIssue, values, flowType, cardType });

  const verifyDetailsMessageLastName = localizedString(
    'verifyDetails.VERIFY_DETAILS_MESSAGE_LAST_NAME'
  );

  if (FLOW_V2_VERIFY_DETAILS_SHOW_CHECKBOX_IF_NO_NAME) {
    validations.firstName = {
      firstName: {
        isRequired: {
          message: localizedString('pleaseEnterFirstName'),
          validateIf: ({ fields }) =>
            fields.confirmFirstNameIsEmpty === '' || fields.confirmFirstNameIsEmpty === false
        },
        isRegexMatch: {
          ...defaultLettersAndSymbolsRegexMatch(),
          validateIf: ({ fields }) =>
            fields.confirmFirstNameIsEmpty === '' || fields.confirmFirstNameIsEmpty === false
        }
      },
      confirmFirstNameIsEmpty: {
        isRequired: {
          message: '',
          validateIf: ({ fields }) => fields.firstName === ''
        }
      }
    };
  }

  if (FLOW_V2_VERIFY_DETAILS_SHOW_ERROR_FOR_1_CHAR_MIDDLE_NAME) {
    validations.middleName = {
      middleName: {
        ...validations.middleName.middleName,
        isMinLength: {
          message: localizedString('pleaseEnterFullMiddleName'),
          length: 2
        }
      }
    };
  }

  validations.lastName = {
    lastName: {
      ...validations.lastName.lastName,
      isRequired: verifyDetailsMessageLastName
    }
  };

  if (FLOW_V2_VERIFY_DETAILS_SHOW_CHECKBOX_IF_NO_NAME) {
    const validateIf = ({ fields }) =>
      fields.confirmLastNameIsEmpty === '' || fields.confirmLastNameIsEmpty === false;

    validations.lastName = {
      lastName: {
        isRequired: {
          message: verifyDetailsMessageLastName,
          validateIf
        },
        isRegexMatch: {
          ...defaultLettersAndSymbolsRegexMatch(),
          validateIf
        }
      },
      confirmLastNameIsEmpty: {
        isRequired: {
          message: '',
          validateIf: ({ fields }) => fields.lastName === ''
        }
      }
    };
  }

  return validations;
}
