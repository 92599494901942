import axios from 'axios';
import APIs from '@services/APIs';

const LOQATE_VERIFICATION_STATUS = {
  VERIFIED: 'V'
};

const BASE_URL = APIs.API_URL;

export default {
  getName,
  find,
  verify,
  canHandleCountry,
  mapRemoteList,
  format
};

function getName() {
  return 'loqate';
}

function canHandleCountry() {
  return true;
}

async function find({ address, country, idHash }) {
  const payload = {
    address: address || undefined,
    country,
    idHash
  };

  const source = axios.CancelToken.source();

  try {
    const { data } = await axios.post(`${BASE_URL}/address/autocomplete/${getName()}`, payload, {
      cancelToken: source.token
    });
    if (!data.Items || data.Items.some(({ Error }) => Error)) {
      throw new Error('Error getting address list');
    }
    return mapRemoteList(data);
  } catch (err) {
    console.error(err);
    throw err;
  }
}

function mapRemoteList(data) {
  return data.Items.map(({ Text, Id, Type, Highlight }) => ({
    text: Text,
    globalAddressKey: Id,
    expand: Type === 'Container',
    highlight: Highlight
  }));
}

async function verify({ address, country }) {
  const payload = {
    address,
    country
  };

  const source = axios.CancelToken.source();
  return axios
    .post(`${BASE_URL}/address/validate/${getName()}`, payload, { cancelToken: source.token })
    .then(({ data }) => {
      const firstMatch = data?.[0]?.Matches?.[0];
      if (firstMatch?.AVC?.startsWith?.(LOQATE_VERIFICATION_STATUS.VERIFIED)) {
        return format(firstMatch, country);
      }
      return {};
    })
    .catch((err) => {
      console.error(err);
      return {};
    });
}

function buildFullAddress(addrObject, { countryIso2Code } = {}) {
  let addressConcatenationOrderMap;
  let mappedData;

  if (countryIso2Code === 'JP') {
    const { SubBuilding = '', Building = '' } = addrObject;
    const unitNumber = SubBuilding || Building;

    mappedData = { ...addrObject, unitNumber };
    addressConcatenationOrderMap = [
      'PostalCode',
      'AdministrativeArea',
      'Locality',
      'Thoroughfare',
      'Premise',
      'unitNumber'
    ];
  }

  if (mappedData && addressConcatenationOrderMap) {
    return addressConcatenationOrderMap
      .flatMap((key) => {
        if (mappedData[key]) {
          return mappedData[key];
        }
        return [];
      })
      .join(' ');
  }

  return addrObject.Address.replaceAll(',', ' ');
}

function format(addrObject, country) {
  const mappedData = {
    address_line_1: addrObject.Address1 || '',
    address_line_2: addrObject.Address2 || '',
    address_line_3: addrObject.Address3 || '',

    locality: addrObject.Locality || '',
    locality_town_name: addrObject.Locality || '',
    suburb: addrObject.DependentLocality || addrObject.Locality || '',

    region: addrObject.AdministrativeArea || '',
    postal_code: addrObject.PostalCode || '',
    country: addrObject.CountryName,

    building_number: addrObject.PremiseNumber || addrObject.Premise || '',
    building_name: addrObject.SubBuilding || '',

    street: addrObject.Thoroughfare,
    street_full_name: addrObject.Thoroughfare || '',
    street_name: addrObject.ThoroughfareName || '',
    street_type: addrObject.ThoroughfareType || '',

    unit_door_full_number: addrObject.SubBuilding,
    unit_door_type: addrObject.SubBuildingType,
    unit_door_value: addrObject.SubBuildingNumber,

    address_api: getName()
  };

  const fullAddress = buildFullAddress(addrObject, { countryIso2Code: country });

  mappedData.fullAddress = fullAddress;
  mappedData.homeAddress = fullAddress;

  return { ...mappedData, provider: getName() };
}
