import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { EVISA_CAPTURE_PHOTO } from '@spotMobileConfig';
import { Tips } from '@components';
import { localizedString } from '@languages';
import { CaptureVisaContent } from '../Contents';
import classes from './CaptureVisa.style.module.scss';

const CaptureVisaP = ({ onSkip, onUploadVisa }) => {
  const [showTip, setShowTip] = useState(false);

  const handleSkip = () => {
    onSkip();
  };

  const handleUploadVisa = () => {
    onUploadVisa();
  };

  const showTips = (val) => {
    setShowTip(val);
  };

  const tipsStates = {
    idType: 'VISA',
    tips: [
      localizedString('holdYourIDInHandCopiesNotAccepted'),
      localizedString('makeSureEdgesAreVisibleInView'),
      localizedString('makeSureNoBlurShadowsGlare')
    ],
    onCapture: handleUploadVisa,
    lastButtonText: localizedString('captureIDNow')
  };

  const buttons = [
    {
      label: localizedString('physicalVisa'),
      onClick: () => showTips(true),
      variant: 'outline'
    },
    {
      label: localizedString('eVisa'),
      onClick: () => (EVISA_CAPTURE_PHOTO ? showTips(true) : handleSkip()),
      variant: 'outline'
    }
  ];

  return (
    <div>
      {!showTip && (
        <div className={classNames(classes.wrapper, 'b-splash-black')}>
          <CaptureVisaContent buttons={buttons} />
        </div>
      )}
      {showTip && <Tips {...tipsStates} />}
    </div>
  );
};

CaptureVisaP.propTypes = {
  onSkip: PropTypes.func,
  onUploadVisa: PropTypes.func
};

export default CaptureVisaP;
