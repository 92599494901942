import Animation, { useAnimationUrls } from '@components/Animation';
import {
  FLOW_V2_LOADING_DETAILS_PROGRESS_BAR,
  HIDE_FOOTER_UPLOAD_SPINNER
} from '@spotMobileConfig';
import { localizedString } from '@languages';
import Page from '@lib/components/v2/Page';
import classes from '@FLOW_V2_FLOW/components/Contents/IdSelection/IdSelection.style.module.scss';
import { Title } from '@lib/components/v2/Title';
import classnames from 'classnames';
import parse from 'html-react-parser';
import LoadingBar from '@lib/components/v2/LoadingBar';
import React from 'react';
import PropTypes from 'prop-types';

export const Loading = ({ isUploading, progressBar }) => {
  const { animationUrls } = useAnimationUrls();
  const footerButtons = [
    {
      label: '',
      variant: 'transparent',
      dataTestId: 'cptrId-back',
      'aria-hidden': true
    }
  ];

  if (!HIDE_FOOTER_UPLOAD_SPINNER) {
    footerButtons.push({
      label:
        isUploading && progressBar < 100
          ? localizedString('uploading')
          : localizedString('loading'),
      variant: 'transparent',
      loading: true,
      dataTestId: 'id-Uploading'
    });
  }

  return (
    <Page buttons={footerButtons} forceFillViewPort>
      <div className={classes.wrapper}>
        <Title
          data-testid="id-heading"
          title={localizedString('idSelection.FLOW_V2_LOADING_DETAILS_HEADING')}
        />
        <div className={classnames(classes.description)} data-testid="id-instruction">
          {parse(localizedString('idSelection.FLOW_V2_LOADING_DETAILS_DESCRIPTION'))}
        </div>
        <div className={classes['animation-container']} data-testid="id-animation">
          <Animation animationUrl={animationUrls.REVIEW} />
        </div>
        {FLOW_V2_LOADING_DETAILS_PROGRESS_BAR && <LoadingBar width={progressBar} />}
      </div>
    </Page>
  );
};

Loading.propTypes = {
  isUploading: PropTypes.bool,
  progressBar: PropTypes.number
};
