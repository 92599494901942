import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LANGUAGES } from '@languages';
import { useLogo } from '@js/hooks/useLogo';
import {
  ENABLE_ONE_DOC_CONDITION,
  DEFAULT_LANGUAGE,
  FEEDBACK_LINK,
  MULTI_LANGUAGE
} from '@spotMobileConfig';
import { Button } from '@lib/components/v2/Form';

import { getAltTextLogo } from '@lib/Utils';
import classes from './Header.style.module.scss';

const Header = ({ hideLogo, isDark, onSelectLanguage, logo }) => {
  const finalLogo = logo ?? useLogo();
  const language = document.body.dataset.language || DEFAULT_LANGUAGE;
  const languageItem = LANGUAGES.find((item) => item.name === language);

  let logoClassName = 'img-container';
  if (ENABLE_ONE_DOC_CONDITION) {
    logoClassName = 'img-container-one-doc';
  }

  const languageTitle = languageItem?.title || 'English';
  return (
    <header className={classNames(classes.warpper, 'clearfix', isDark && classes.isDark)}>
      <div className={classes[logoClassName]} data-testid="header-title">
        {!hideLogo && <img alt={getAltTextLogo()} src={finalLogo} />}
      </div>

      <div className={classes['right-items']}>
        <div>
          {MULTI_LANGUAGE && onSelectLanguage && (
            <Button
              variant="transparent"
              data-testid="header-language"
              aria-label={`Select your language: ${languageTitle}`}
              className={classes['language-chooser']}
              onClick={onSelectLanguage}
            >
              {languageTitle}
              <img alt="" src="images/icons/png/forward-videoid.png" aria-hidden="true" />
            </Button>
          )}

          {FEEDBACK_LINK && (
            <a
              className={classes['feedback-link']}
              target="_blank"
              href={FEEDBACK_LINK}
              rel="noreferrer"
            >
              Feedback
            </a>
          )}
        </div>
      </div>
    </header>
  );
};

Header.propTypes = {
  /* If its `true` the header position will be fixed */
  isDark: PropTypes.bool,
  hideLogo: PropTypes.bool,
  onSelectLanguage: PropTypes.func,
  logo: PropTypes.string
};

Header.defaultProps = {
  hideLogo: false
};

export default Header;
