import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Title } from '@lib/components/v2/Title';
import { Button, ButtonGroup } from '@lib/components/v2/Form';
import classes from './Modal.style.module.scss';

/**
 * A sample Modal
 */
const Modal = ({ children, isOpen, small, heading, buttons, success, className }) => {
  return (
    <div
      className={classNames(classes.wrapper, isOpen && classes.open, className)}
      role={buttons ? 'alertdialog' : 'dialog'}
      aria-modal="true"
      aria-label={heading}
      aria-hidden={isOpen ? 'false' : 'true'}
    >
      <div
        className={classNames(classes.modalContent, isOpen && classes.open, small && classes.small)}
      >
        <div className={classNames(classes.dialog)}>
          <div className="text-center">
            {!success && (
              <img alt="" width="64" src="images/icons/png/issue-videoid.png" aria-hidden="true" />
            )}
            {success && (
              <img alt="" width="64" src="images/icons/svg/icon_completed.svg" aria-hidden="true" />
            )}
          </div>
          {heading && isOpen && <Title className={classes.heading} title={heading} />}
          {children}
          {buttons.length > 0 && (
            <div className={classes.footer}>
              <ButtonGroup btnCount={buttons.length}>
                {buttons.map((button) => (
                  <Button key={button.label} {...button} tabIndex={isOpen ? undefined : -1} />
                ))}
              </ButtonGroup>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  small: PropTypes.bool,
  success: PropTypes.bool,
  heading: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
      shadow: PropTypes.bool,
      large: PropTypes.bool
    })
  )
};

Modal.defaultProps = {
  isOpen: false,
  success: false
};

export default Modal;
