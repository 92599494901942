import cloneDeep from 'lodash/cloneDeep';
import { localizedString } from '@languages';

import { noEmojies } from '@lib/constants/regex';
import { isEmptyExpiryDateAllowed } from '@lib/utils/checkExpiredDocument';
import { DL_NUMBER_VALIDATION } from '@spotMobileConfig';
import { DOCUMENTS } from '../constants/documents';

export const defaultLettersAndSymbolsRegexMatch = () => ({
  message: localizedString('lettersAndSymbolsOnly'),
  regex: noEmojies
});

const defaultLettersAndNumbersRegexMatch = () => ({
  message: localizedString('lettersAndNumbersOnly'),
  regex: /^([a-zA-Z0-9]+)$/
});

export const defaultLettersAndNumbersAndSpaceRegexMatch = () => ({
  message: localizedString('lettersAndNumbersAndSpaceOnly'),
  regex: /^([a-zA-Z0-9 ]+)$/
});

const defaultLettersAndNumbersAndParenthesisRegexMatch = () => ({
  message: localizedString('lettersAndNumbersAndParenthesisOnly'),
  regex: /^([a-zA-Z0-9()]+)$/
});

const defaultLettersAndNumbersAndHyphenRegexMatch = () => ({
  message: localizedString('lettersAndNumbersAndHyphenOnly'),
  regex: /^([a-zA-Z0-9-]+)$/
});

const maxLength50CharsValidation = () => ({
  message: localizedString('max50Chars'),
  length: 50
});

const firstName = () => ({
  isRequired: localizedString('pleaseEnterFirstName'),
  isRegexMatch: defaultLettersAndSymbolsRegexMatch(),
  isMaxLength: maxLength50CharsValidation()
});

const middleName = () => ({
  isRegexMatch: defaultLettersAndSymbolsRegexMatch(),
  isMaxLength: maxLength50CharsValidation()
});

const lastName = () => ({
  isRequired: localizedString('verifyDetails.VERIFY_DETAILS_MESSAGE_LAST_NAME'),
  isRegexMatch: defaultLettersAndSymbolsRegexMatch(),
  isMaxLength: maxLength50CharsValidation()
});

const fullName = () => ({
  isRequired: localizedString('verifyDetails.VERIFY_DETAILS_MESSAGE_FULL_NAME'),
  isRegexMatch: defaultLettersAndSymbolsRegexMatch(),
  isMaxLength: maxLength50CharsValidation()
});

const dateOfBirth = () => ({
  isRequired: localizedString('pleaseEnterDateOfBirth'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/]+)$/
  }
});

const expiryDate = () => ({
  isRequired: localizedString('pleaseEnterDateOfExpiry'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/]+)$/
  }
});

const visaStartDate = () => ({
  isRequired: localizedString('pleaseEnterVisaStartDate'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/]+)$/
  }
});

const visaEndDate = () => ({
  isRequired: localizedString('pleaseEnterVisaEndDate'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/]+)$/
  }
});

const licenceNumber = () => {
  const validations = {
    isRequired: localizedString('pleaseEnterLicenceNumber'),
    isRegexMatch: defaultLettersAndNumbersRegexMatch(),
    isMinLength: {
      message: localizedString('4CharactersMinimum'),
      length: 4
    }
  };

  if (DL_NUMBER_VALIDATION) {
    validations.isExactLength = {
      message: localizedString('licenceNumberMustBe8Chars'),
      length: 8
    };
  }

  return validations;
};

const licenceVersion = () => ({
  isRequired: localizedString('pleaseEnterVersionNumber'),
  isRegexMatch: {
    message: localizedString('numbersOnly'),
    regex: /^([0-9]+)$/
  },
  isExactLength: {
    message: localizedString('versionNumberMustBe3Numbers'),
    length: 3
  }
});

const passportNumber = () => ({
  isRequired: localizedString('pleaseEnterPassportNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch(),
  isMaxLength: {
    message: localizedString('alphaNumeric9DigitsMaximum'),
    length: 9
  }
});

const cardNumber = () => ({
  isRequired: localizedString('pleaseEnterCardNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch(),
  isMaxLength: {
    message: localizedString('max10Chars'),
    length: 10
  }
});

const cardNumberVIC = () => ({
  isRequired: localizedString('pleaseEnterCardNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch(),
  isExactLength: {
    message: localizedString('cardNumberMustBe8Chars'),
    length: 8
  }
});

const idNumber = () => ({
  default: {
    isRequired: localizedString('pleaseEnterIDNumber'),
    isRegexMatch: defaultLettersAndNumbersRegexMatch(),
    isMaxLength: maxLength50CharsValidation()
  },
  'HONG KONG': {
    isRequired: localizedString('pleaseEnterIDNumber'),
    isRegexMatch: defaultLettersAndNumbersAndParenthesisRegexMatch(),
    isMaxLength: maxLength50CharsValidation()
  },
  [DOCUMENTS.DRIVER_LICENCE.cardType]: {
    CANADA: {
      isRequired: localizedString('pleaseEnterIDNumber'),
      isRegexMatch: defaultLettersAndNumbersAndHyphenRegexMatch(),
      isMaxLength: maxLength50CharsValidation()
    },
    'NEW ZEALAND': {
      isRequired: localizedString('pleaseEnterIDNumber'),
      isRegexMatch: defaultLettersAndNumbersRegexMatch(),
      isExactLength: {
        message: localizedString('licenceNumberMustBe8Chars'),
        length: 8
      }
    }
  }
});

const taxNumber = () => ({
  isRequired: localizedString('pleaseEnterTaxNumber'),
  isRegexMatch: {
    message: localizedString('numbersDashAndDotsOnly'),
    regex: /^([0-9.-]+)$/
  },
  isMaxLength: maxLength50CharsValidation()
});

const documentNumber = () => ({
  isRequired: localizedString('pleaseEnterDocumentNumber'),
  isRegexMatch: defaultLettersAndNumbersRegexMatch(),
  isMaxLength: maxLength50CharsValidation()
});

const dlclassCodeBFrom = () => ({
  isRequired: localizedString('pleaseEnterBClassIssueDate'),
  isRegexMatch: {
    message: localizedString('invalidDate'),
    regex: /^([0-9-/.]+)$/
  }
});

const unitNumber = () => ({
  isRegexMatch: defaultLettersAndSymbolsRegexMatch(), // TODO: maybe allow everthing???
  isMaxLength: maxLength50CharsValidation()
});

export const getFieldsValidation = ({
  idType = '',
  countryOfIssue = '',
  values = {},
  flowType = '',
  cardType = ''
}) => {
  const { state } = values;
  const cardNumberValidation = isVicDL({ idType, countryOfIssue, state })
    ? cardNumberVIC()
    : cardNumber();
  const idTypeValue = idNumber()[idType]?.[countryOfIssue.toUpperCase()];
  const countryValue = idNumber()[countryOfIssue.toUpperCase()];
  const idNumberValidation = idTypeValue || countryValue || idNumber().default;
  const { isRequired, ...expiryDateWithoutRequired } = expiryDate();

  return cloneDeep({
    firstName: { firstName: firstName() },
    lastName: { lastName: lastName() },
    middleName: { middleName: middleName() },
    fullName: { fullName: fullName() },
    dateOfBirth: { dateOfBirth: dateOfBirth() },
    expiryDate: {
      expiryDate: isEmptyExpiryDateAllowed({ flowType, cardType })
        ? expiryDateWithoutRequired
        : expiryDate()
    },
    visaStartDate: { visaStartDate: visaStartDate() },
    visaEndDate: { visaEndDate: visaEndDate() },
    licenceNumber: { licenceNumber: licenceNumber() },
    licenceVersion: { licenceVersion: licenceVersion() },
    passportNumber: { passportNumber: passportNumber() },
    cardNumber: { cardNumber: cardNumberValidation },
    idNumber: { idNumber: idNumberValidation },
    taxNumber: { taxNumber: taxNumber() },
    documentNumber: { documentNumber: documentNumber() },
    dlclassCodeBFrom: { dlclassCodeBFrom: dlclassCodeBFrom() },
    unitNumber: { unitNumber: unitNumber() }
  });
};

function isVicDL({ idType, countryOfIssue, state }) {
  return (
    idType.match(/LICENCE/i) && countryOfIssue.toUpperCase() === 'AUSTRALIA' && state === 'VIC'
  );
}
